import React from "react";
import s from "./MeetingLabel.scss";
import {NavHashLink as NavLink} from "react-router-hash-link";

export default class MeetingLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="meeting-label">
         <NavLink className="meeting-label__link" to={"/spotkanie"}>
         Spotkanie online
        </NavLink>
        <NavLink className="meeting-label__link" to={"/spotkanie"}>
          <span className="af-icon-camera meeting-label__icon"></span>
        </NavLink>
       
      </div>
    );
  }
}
