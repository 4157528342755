import React from "react";
import s from "./BannerSideContact.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";

export default class BannerSideContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="banner__side-contact">
        <a href="tel:0048717261357" className="side__link">
          <span className="af-icon-phone side__icon"></span>
        </a>
        <NavLink
          className="side__link"
          smooth
          scroll={(el) => {
            this.scrollWithOffset(el);
          }}
          onClick={(e) => {
            if (window.location.pathname == "/") {
              e.preventDefault();
            }
          }}
          to={"/#kontakt"}
        >
          <span className="af-icon-mail side__icon"></span>
        </NavLink>
        <a
          href="https://www.facebook.com/ProjektyDeweloperskieHossanova/"
          className="side__link"
          target="_blank"
        >
          <span className="af-icon-facebook side__icon"></span>
        </a>

        {/* <NavLink className="side__link meeting" to={"/spotkanie"}>
          <span className="af-icon-camera side__icon"></span>
        </NavLink>
        <NavLink className="side__link meeting-text" to={"/spotkanie"}>
          <span>Spotkanie online</span>
        </NavLink> */}
      </div>
    );
  }
}
