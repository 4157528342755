import React from "react";
import "./Detail.scss";
import image from "../../assets/images/detail-image.webp";
import imageA from "../../assets/images/detail-image.jpg";

export default class Detail extends React.Component {
  render() {
    return (
      <section className="lp-detail">
        <div className="detail__container container">
          <div className="detail__main row">
            <div className="detail__col col-6 col-m-12">
              <div className="detail__image">
                <picture>
                  <source srcset={image} type="image/webp" />
                  <source srcset={imageA} type="image/jpeg" />
                  <img src={imageA} />
                </picture>
              </div>
            </div>
            <div className="detail__col col-6 col-m-12">
              <div className="detail__description">
                <h2 className="detail__title title-basic">
                  Inspirująca siła detalu
                </h2>
                {/* <p className="detail__text text-basic">
                  Inwestycja jest przede wszystkim bezpieczna dla dzieci. Tu
                  można wyjść na spacer, pobawić się na placu zabaw, pobiegać
                  wzdłuż ścieżki. Wszystko w zasięgu troskliwego wzroku
                  rodziców.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
