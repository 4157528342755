import React, {Component} from "react";
import s from "./FloorViewLabel.scss";

function numberWithCommas(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(2))
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(",", " ")
    .replace(".", ",");
}

export default class FloorViewLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleApartmentStatus = flatStatus => {
    if (flatStatus === 1) {
      flatStatus = "Wolne";
    } else if (flatStatus === 2) {
      flatStatus = "Rezerwacja";
    } else if (flatStatus === 3) {
      flatStatus = "Sprzedane";
    }
    return flatStatus;
  };

  render() {
    let flatNumber = this.props.flatNumber.replace(/\./g, "/");
    let flat = this.props.flats.find(
      element => element.flatNumber === flatNumber
    );

    if (!flat || flat === undefined) {
      return null;
    } else {
      return (
        <div className="floor-view__label">
          <h2 className="floor-view__label-title">
            {flatNumber} {flat.rooms.replace("*", "")}
          </h2>

          <p className="floor-view__label-text">
            <span>Powierzchnia:</span>
            {flat.area} m<sup>2</sup>
          </p>
          <p className="floor-view__label-text">
            <span>Pokoje:</span>
            {flat.rooms.slice(1, 2)}
          </p>

          <p className="floor-view__label-text">
            <span>Status:</span>
            {this.handleApartmentStatus(flat.flatStatus)}
          </p>
          <p className="floor-view__label-text">
            <span>Cena:</span>

            {flat.flatStatus == 3
              ? " - "
              : flat.flatStatus == 2
              ? " - "
              : flat.brutto
              ? numberWithCommas(flat.brutto) + " zł"
              : " - "}
          </p>
        </div>
      );
    }
  }
}
