import React from "react";
import s from "./TimeSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import time from "../../assets/images/time-background.webp";
import timeA from "../../assets/images/time-background.jpg";

import time1 from "../../assets/images/time1.webp";
import time1a from "../../assets/images/time1.jpg";

import time2 from "../../assets/images/time2.webp";
import time2a from "../../assets/images/time2.jpg";

export default class TimeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="lp-time">
        <div className="time__container container-new">
          <div className="time__top row">
            <div className="time__top-left col-8 col-m-12">
              <picture className="time__background">
                <source srcset={time} type="image/webp" />
                <source srcset={timeA} type="image/jpeg" />
                <img src={timeA} className="time__background" />
              </picture>
            </div>

            <div className="time__top-right col-4 col-m-12">
              <h2 className="time__title text-basic">Czas wolny</h2>
              <p className="time__text text-basic">
                Znajdź balans między pracą a czasem wolnym. Lokalizacja nad
                rzeką niedaleko miejsc stworzonych do wypoczynku i rekreacji.
                Bezpośrednio w budynku znajdą się siłownia i Klub Malucha.
              </p>

              <Link
                to="/lista-apartamentow"
                className="time__button button-basic button-black"
              >
                Lista mieszkań
              </Link>
            </div>
          </div>

          <div className="time__bottom row">
            <div className="time__bottom-left col-4 col-m-12">
              <picture className="time__background">
                <source srcset={time1} type="image/webp" />
                <source srcset={time1a} type="image/jpeg" />
                <img src={time1a} className="time__background" />
              </picture>
            </div>

            <div className="time__bottom-right col-8 col-m-12">
              <picture className="time__background">
                <source srcset={time2} type="image/webp" />
                <source srcset={time2a} type="image/jpeg" />
                <img src={time2a} className="time__background" />
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
