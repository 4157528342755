import React from "react";
import s from "./BusinessSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import business from "../../assets/images/business-background.webp";
import businessA from "../../assets/images/business-background.jpg";

export default class BusinessSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="lp-business">
        <div className="business__container container-new">
          <div className="business__top row">
            <div className="business__top-left col-8 col-m-12">
              <picture className="business__background">
                <source srcset={business} type="image/webp" />
                <source srcset={businessA} type="image/jpeg" />
                <img src={businessA} className="business__background" />
              </picture>
            </div>

            <div className="business__top-right col-4 col-m-12">
              <h2 className="business__title text-basic">Blisko biznesu</h2>
              <p className="business__text text-basic">
                Inwestycja jest bardzo dobrze skomunikowana z centrami
                biznesowymi na Nadodrzu, przy ul, Legnickiej. W bezpośredniej
                okolicy planowane są kolejne biurowce.
              </p>

              <Link
                to="/lista-apartamentow"
                className="business__button button-basic button-black"
              >
                Lista mieszkań
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
