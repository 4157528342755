import React from "react";

import "./PrivacyPage.scss";
import About from "../About/";
import ContactSection from "../ContactSection";
import Privacy from "../Privacy/";

export default class PrivacyPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <div className="lp-privacy-page">
        <Privacy />

        <About
          title="Hossanova"
          text='Nasze projekty budowlane to przemyślane koncepcje w których bierzemy pod uwagę każde istotne detale, by spełnić wymagania przyszłych właścicieli. Spośród lokalizacji, wybieramy te, które odpowiadają naszym wysokim standardom. Zapraszamy do współpracy właścicieli gruntu, posiadających wiedzę i informację o atrakcyjnym miejscu, które spełnia nasze wymagania.'
        />
        <ContactSection />
      </div>
    );
  }
}
