import React, {Component} from "react";
import s from "./LocalizationInfo.scss";

export default class LocalizationSliderItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="slider-item">
        <div className="slider-item__image">
          <img src={this.props.data.imgSrc} alt={this.props.data.title}></img>
        </div>
      </div>
    );
  }
}
