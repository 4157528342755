import React from "react";
import { NavLink } from "react-router-dom";
import "./Search.scss";
export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.inputs = {
      areaFrom: React.createRef(),

      area_from_30: React.createRef(),
      area_from_40: React.createRef(),
      area_from_50: React.createRef(),
      area_from_60: React.createRef(),
      area_from_70: React.createRef(),
      areaTo: React.createRef(),

      area_to_40: React.createRef(),
      area_to_50: React.createRef(),
      area_to_60: React.createRef(),
      area_to_70: React.createRef(),
      area_to_100: React.createRef(),

      floorFrom: React.createRef(),

      room_from_1: React.createRef(),
      room_from_2: React.createRef(),
      room_from_3: React.createRef(),
      room_from_4: React.createRef(),
      room_to_1: React.createRef(),
      room_to_2: React.createRef(),
      room_to_3: React.createRef(),
      room_to_4: React.createRef(),

      east: React.createRef(),
      west: React.createRef(),
      north: React.createRef(),
      south: React.createRef(),

      storey_from_0: React.createRef(),
      storey_from_1: React.createRef(),
      storey_from_2: React.createRef(),
      storey_from_3: React.createRef(),
      storey_from_4: React.createRef(),
      storey_from_5: React.createRef(),
      storey_from_6: React.createRef(),
      // storey_from_7: React.createRef(),
      // storey_from_8: React.createRef(),

      storey_to_0: React.createRef(),
      storey_to_1: React.createRef(),
      storey_to_2: React.createRef(),
      storey_to_3: React.createRef(),
      storey_to_4: React.createRef(),
      storey_to_5: React.createRef(),
      storey_to_6: React.createRef(),
      // storey_to_7: React.createRef(),
      // storey_to_8: React.createRef(),

      floorTo: React.createRef(),
      priceFrom: React.createRef(),
      priceTo: React.createRef(),
      roomsFrom: React.createRef(),
      roomsTo: React.createRef(),

      garden: React.createRef(),
      balcony: React.createRef(),
      wardrobe: React.createRef(),
      loggia: React.createRef(),

      free: React.createRef(),
      promotions: React.createRef(),
    };

    this.state = {
      params: false,
      mobileShowFilters: false,
    };
  }

  toggleFiltersMobile = (event) => {
    event.preventDefault();
    this.setState({
      mobileShowFilters: !this.state.mobileShowFilters,
    });
  };

  getParentParam(paramName) {
    if (typeof this.props.searchParams == "undefined") {
      return null;
    }

    let params = this.props.searchParams;
    if (typeof params[paramName] != "undefined") {
      return params[paramName];
    }

    return null;
  }

  shouldBeChecked = (thisInput) => {
    let paramName = thisInput.props.paramName;

    let value = thisInput.props.value;
    let onlyone = typeof thisInput.props.onlyone != "undefined";
    let param = this.getParentParam(paramName);

    if (param) {
      return value == param || (onlyone && param == 1);
    }

    return false;
  };

  getInputFromLabel = (labelRef) => {
    return document.getElementById(labelRef.getAttribute("for"));
  };

  clickRadio = (event) => {
    let radio = this.getInputFromLabel(event.target);
    if (radio.checked) {
      setTimeout(() => {
        radio.checked = false;
        radio.defaultChecked = false;
      }, 1);
    }
  };

  applyFilters = (event) => {
    event.preventDefault();

    //POWIERZCHNIA UŻYTKOWA - od, do
    //KONDYGNACJA - od, do
    //CENA BRUTTO - od, do
    //ILOŚĆ - od, do
    //OKNA OD STRONY - WSCHÓD, ZACHÓD, PÓŁNOC, POŁUDNIE
    //DOBIERZ CECHY MIESZKANIA - OGRÓD, GARDEROBA, TARAS, ANTRESOLA
    //POKAŻ WYBRANE - POKAŻ TYLKO WOLNE POKAŻ, TYLKO PROMOCJE

    let areaFrom = 0;
    areaFrom = this.inputs.area_from_30.current.checked
      ? this.inputs.area_from_30.current.value
      : areaFrom;
    areaFrom = this.inputs.area_from_40.current.checked
      ? this.inputs.area_from_40.current.value
      : areaFrom;
    areaFrom = this.inputs.area_from_50.current.checked
      ? this.inputs.area_from_50.current.value
      : areaFrom;
    areaFrom = this.inputs.area_from_60.current.checked
      ? this.inputs.area_from_60.current.value
      : areaFrom;
    areaFrom = this.inputs.area_from_70.current.checked
      ? this.inputs.area_from_70.current.value
      : areaFrom;

    let areaTo = 0;
    areaTo = this.inputs.area_to_40.current.checked
      ? this.inputs.area_to_40.current.value
      : areaTo;
    areaTo = this.inputs.area_to_50.current.checked
      ? this.inputs.area_to_50.current.value
      : areaTo;
    areaTo = this.inputs.area_to_60.current.checked
      ? this.inputs.area_to_60.current.value
      : areaTo;
    areaTo = this.inputs.area_to_70.current.checked
      ? this.inputs.area_to_70.current.value
      : areaTo;
    areaTo = this.inputs.area_to_100.current.checked
      ? this.inputs.area_to_100.current.value
      : areaTo;

    let room_from = 0;
    room_from = this.inputs.room_from_1.current.checked
      ? this.inputs.room_from_1.current.value
      : room_from;
    room_from = this.inputs.room_from_2.current.checked
      ? this.inputs.room_from_2.current.value
      : room_from;
    room_from = this.inputs.room_from_3.current.checked
      ? this.inputs.room_from_3.current.value
      : room_from;
    room_from = this.inputs.room_from_4.current.checked
      ? this.inputs.room_from_4.current.value
      : room_from;

    let room_to = 0;
    room_to = this.inputs.room_to_1.current.checked
      ? this.inputs.room_to_1.current.value
      : room_to;
    room_to = this.inputs.room_to_2.current.checked
      ? this.inputs.room_to_2.current.value
      : room_to;
    room_to = this.inputs.room_to_3.current.checked
      ? this.inputs.room_to_3.current.value
      : room_to;
    room_to = this.inputs.room_to_4.current.checked
      ? this.inputs.room_to_4.current.value
      : room_to;

    let storey_from = -1;
    storey_from = this.inputs.storey_from_0.current.checked
      ? this.inputs.storey_from_0.current.value
      : storey_from;
    storey_from = this.inputs.storey_from_1.current.checked
      ? this.inputs.storey_from_1.current.value
      : storey_from;
    storey_from = this.inputs.storey_from_2.current.checked
      ? this.inputs.storey_from_2.current.value
      : storey_from;

    storey_from = this.inputs.storey_from_3.current.checked
      ? this.inputs.storey_from_3.current.value
      : storey_from;

    storey_from = this.inputs.storey_from_4.current.checked
      ? this.inputs.storey_from_4.current.value
      : storey_from;

    storey_from = this.inputs.storey_from_5.current.checked
      ? this.inputs.storey_from_5.current.value
      : storey_from;

    storey_from = this.inputs.storey_from_6.current.checked
      ? this.inputs.storey_from_6.current.value
      : storey_from;

    // storey_from = this.inputs.storey_from_7.current.checked
    //   ? this.inputs.storey_from_7.current.value
    //   : storey_from;

    // storey_from = this.inputs.storey_from_8.current.checked
    //   ? this.inputs.storey_from_8.current.value
    //   : storey_from;

    let storey_to = -1;
    storey_to = this.inputs.storey_to_0.current.checked
      ? this.inputs.storey_to_0.current.value
      : storey_to;
    storey_to = this.inputs.storey_to_1.current.checked
      ? this.inputs.storey_to_1.current.value
      : storey_to;
    storey_to = this.inputs.storey_to_2.current.checked
      ? this.inputs.storey_to_2.current.value
      : storey_to;
    storey_to = this.inputs.storey_to_3.current.checked
      ? this.inputs.storey_to_3.current.value
      : storey_to;
    storey_to = this.inputs.storey_to_4.current.checked
      ? this.inputs.storey_to_4.current.value
      : storey_to;

    storey_to = this.inputs.storey_to_5.current.checked
      ? this.inputs.storey_to_5.current.value
      : storey_to;

    storey_to = this.inputs.storey_to_6.current.checked
      ? this.inputs.storey_to_6.current.value
      : storey_to;

    // storey_to = this.inputs.storey_to_7.current.checked
    //   ? this.inputs.storey_to_7.current.value
    //   : storey_to;

    // storey_to = this.inputs.storey_to_8.current.checked
    //   ? this.inputs.storey_to_8.current.value
    //   : storey_to;

    // :areaFrom,:areaTo,:floorFrom,:floorTo,:priceFrom,:priceTo,:roomsFrom,:roomsTo,:east,:west,:north,:south,:garden,:balcony,:wardrobe,:loggia,:free,:promotions" component={(props)

    this.props.redirectTo(
      "/znajdz-apartamenty/" +
        areaFrom +
        "," +
        areaTo +
        "," +
        storey_from +
        "," +
        storey_to +
        "," +
        "0," +
        "0," +
        room_from +
        "," +
        room_to +
        "," +
        ((this.inputs.east.current.checked && 1) || 0) +
        "," +
        ((this.inputs.west.current.checked && 1) || 0) +
        "," +
        ((this.inputs.north.current.checked && 1) || 0) +
        "," +
        ((this.inputs.south.current.checked && 1) || 0) +
        "," +
        ((this.inputs.garden.current.checked && 1) || 0) +
        "," +
        ((this.inputs.balcony.current.checked && 1) || 0) +
        "," +
        ((this.inputs.wardrobe.current.checked && 1) || 0) +
        "," +
        ((this.inputs.loggia.current.checked && 1) || 0) +
        "," +
        ((this.inputs.free.current.checked && 1) || 0) +
        "," +
        ((this.inputs.promotions.current.checked && 1) || 0)
    );

    // this.props.redirectTo(
    //   "/lista-apartamentow/0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0"
    // );
  };

  componentDidMount = () => {
    if (
      typeof this.props.searchParams != "undefined" &&
      Object.keys(this.props.searchParams).length > 0
    ) {
      this.setState({ params: this.props.searchParams });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      if (nextProps !== "undefined" && Object.keys(nextProps).length > 0) {
        this.setState({
          params: nextProps.searchParams,
        });
      }
    }
  };

  putVals = () => {
    setTimeout(() => {
      for (var property in this.state.params) {
        if (this.state.params.hasOwnProperty(property)) {
          let current = this.inputs[property].current;
          let number = Number(this.state.params[property]);

          if (current) {
            if (current.type === "checkbox") {
              current.checked = number;
            } else if (number !== 0) {
              current.value = number;
            }
          }
        }
      }
    }, 1);
  };

  render() {
    let storeyFromData = [
      {
        name: "storey_from",
        refer: this.inputs.storey_from_0,
        id: "storey_from_0",
        value: "0",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_1,
        id: "storey_from_1",
        value: "1",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_2,
        id: "storey_from_2",
        value: "2",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_3,
        id: "storey_from_3",
        value: "3",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_4,
        id: "storey_from_4",
        value: "4",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_5,
        id: "storey_from_5",
        value: "5",
        paramName: "floorFrom",
      },
      {
        name: "storey_from",
        refer: this.inputs.storey_from_6,
        id: "storey_from_6",
        value: "6",
        paramName: "floorFrom",
      },
      // {
      //   name: "storey_from",
      //   refer: this.inputs.storey_from_7,
      //   id: "storey_from_7",
      //   value: "7",
      //   paramName: "floorFrom",
      // },
      // {
      //   name: "storey_from",
      //   refer: this.inputs.storey_from_8,
      //   id: "storey_from_8",
      //   value: "8",
      //   paramName: "floorFrom",
      // },
    ];

    let storeyToData = [
      {
        name: "storey_to",
        refer: this.inputs.storey_to_0,
        id: "storey_to_0",
        value: "0",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_1,
        id: "storey_to_1",
        value: "1",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_2,
        id: "storey_to_2",
        value: "2",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_3,
        id: "storey_to_3",
        value: "3",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_4,
        id: "storey_to_4",
        value: "4",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_5,
        id: "storey_to_5",
        value: "5",
        paramName: "floorTo",
      },
      {
        name: "storey_to",
        refer: this.inputs.storey_to_6,
        id: "storey_to_6",
        value: "6",
        paramName: "floorTo",
      },
      // {
      //   name: "storey_to",
      //   refer: this.inputs.storey_to_7,
      //   id: "storey_to_7",
      //   value: "7",
      //   paramName: "floorTo",
      // },
      // {
      //   name: "storey_to",
      //   refer: this.inputs.storey_to_8,
      //   id: "storey_to_8",
      //   value: "8",
      //   paramName: "floorTo",
      // },
    ];

    let areaFrom = [
      {
        name: "area_from",
        refer: this.inputs.area_from_30,
        id: "area_from_30",
        value: "30",
        paramName: "areaFrom",
      },
      {
        name: "area_from",
        refer: this.inputs.area_from_40,
        id: "area_from_40",
        value: "40",
        paramName: "areaFrom",
      },
      {
        name: "area_from",
        refer: this.inputs.area_from_50,
        id: "area_from_50",
        value: "50",
        paramName: "areaFrom",
      },
      {
        name: "area_from",
        refer: this.inputs.area_from_60,
        id: "area_from_60",
        value: "60",
        paramName: "areaFrom",
      },
      {
        name: "area_from",
        refer: this.inputs.area_from_70,
        id: "area_from_70",
        value: "70",
        paramName: "areaFrom",
      },
    ];

    let areaTo = [
      {
        name: "area_to",
        refer: this.inputs.area_to_40,
        id: "area_to_40",
        value: "40",
        paramName: "areaTo",
      },
      {
        name: "area_to",
        refer: this.inputs.area_to_50,
        id: "area_to_50",
        value: "50",
        paramName: "areaTo",
      },
      {
        name: "area_to",
        refer: this.inputs.area_to_60,
        id: "area_to_60",
        value: "60",
        paramName: "areaTo",
      },
      {
        name: "area_to",
        refer: this.inputs.area_to_70,
        id: "area_to_70",
        value: "70",
        paramName: "areaTo",
      },
      {
        name: "area_to",
        refer: this.inputs.area_to_100,
        id: "area_to_100",
        value: "100",
        paramName: "areaTo",
      },
    ];

    let roomsFrom = [
      {
        name: "room_from",
        refer: this.inputs.room_from_1,
        id: "room_from_1",
        value: "1",
        paramName: "roomsFrom",
      },
      {
        name: "room_from",
        refer: this.inputs.room_from_2,
        id: "room_from_2",
        value: "2",
        paramName: "roomsFrom",
      },
      {
        name: "room_from",
        refer: this.inputs.room_from_3,
        id: "room_from_3",
        value: "3",
        paramName: "roomsFrom",
      },
      {
        name: "room_from",
        refer: this.inputs.room_from_4,
        id: "room_from_4",
        value: "4",
        paramName: "roomsFrom",
      },
    ];

    let roomsTo = [
      {
        name: "room_to",
        refer: this.inputs.room_to_1,
        id: "room_to_1",
        value: "1",
        paramName: "roomsTo",
      },

      {
        name: "room_to",
        refer: this.inputs.room_to_2,
        id: "room_to_2",
        value: "2",
        paramName: "roomsTo",
      },

      {
        name: "room_to",
        refer: this.inputs.room_to_3,
        id: "room_to_3",
        value: "3",
        paramName: "roomsTo",
      },

      {
        name: "room_to",
        refer: this.inputs.room_to_4,
        id: "room_to_4",
        value: "4",
        paramName: "roomsTo",
      },
    ];

    let investmentFrom = [
      {
        name: "investment_from",
        refer: this.inputs.east,
        id: "wschod",
        value: "wschod",
        paramName: "east",
      },

      {
        name: "investment_from",
        refer: this.inputs.west,
        id: "zachod",
        value: "zachod",
        paramName: "west",
      },

      {
        name: "investment_from",
        refer: this.inputs.north,
        id: "polnoc",
        value: "polnoc",
        paramName: "north",
      },

      {
        name: "investment_from",
        refer: this.inputs.south,
        id: "poludnie",
        value: "poludnie",
        paramName: "south",
      },
    ];

    const Input = (props) => {
      let inputComponent = <input ref={props.refer} {...props} />;

      try {
        // inputComponent.props.checked = this.shouldBeChecked(inputComponent);
        inputComponent = (
          <input
            ref={props.refer}
            {...props}
            defaultChecked={this.shouldBeChecked(inputComponent)}
          />
        );
      } catch (err) {
        // console.log("ERR", err);
      }
      return inputComponent;
    };

    if (this.state.params) {
      this.putVals();
    }

    return (
      <section className="lp-search">
        <div className="search__container container">
          <h2 className="search__title title-basic col-12">
            Wybierz kryteria wyszukiwania
          </h2>

          <form className="search__form" onSubmit={this.applyFilters}>
            <span
              onClick={this.toggleFiltersMobile}
              className="button search__filters-show button-basic"
            >
              {this.state.mobileShowFilters ? "Ukryj filtry" : "Pokaż filtry"}
            </span>

            <div
              className={
                "row container search__criteria__list" +
                (this.state.mobileShowFilters ? " mobile-active" : "")
              }
            >
              <div className="col-4 col-l-6 col-m-6 col-s-12 search__column">
                <div className="search__criteria">
                  <span className="search__text text-basic">
                    Powierzchnia m<sup>2</sup>
                  </span>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Od</span>
                    <ul className="horizontal-radio">
                      {areaFrom.map((element, index) => (
                        <li key={"searchInput" + index}>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Do</span>
                    <ul className="horizontal-radio">
                      {areaTo.map((element) => (
                        <li>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-3 col-l-6 col-m-6 col-s-12 search__column">
                <div className="search__criteria">
                  <span className="search__text text-basic">Liczba pokoi</span>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Od</span>
                    <ul className="horizontal-radio">
                      {roomsFrom.map((element) => (
                        <li>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Do</span>
                    <ul className="horizontal-radio">
                      {roomsTo.map((element) => (
                        <li>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value === "99" ? "+" : element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-5 col-l-12 col-m-12 col-s-12 search__column">
                <div className="search__criteria">
                  <span className="search__text text-basic">Piętro</span>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Od</span>
                    <ul className="horizontal-radio storey">
                      {storeyFromData.map((element) => (
                        <li>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="horizontal-radio-parent">
                    <span className="search__text text-basic">Do</span>
                    <ul className="horizontal-radio storey">
                      {storeyToData.map((element) => (
                        <li>
                          <Input
                            refer={element.refer}
                            type="radio"
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-4 col-l-6 col-m-6 col-s-12 search__column">
                <div className="search__criteria vertical">
                  <span className="search__text text-basic">
                    Okna od strony
                  </span>

                  <div className="horizontal-radio-parent vertical">
                    <ul className="horizontal-radio vertical">
                      {investmentFrom.map((element) => (
                        <li>
                          <Input
                            type="radio"
                            onlyone={true}
                            refer={element.refer}
                            name={element.name}
                            paramName={element.paramName}
                            id={element.id}
                            value={element.value}
                          />
                          <label
                            className="label__text text-basic"
                            onClick={this.clickRadio}
                            htmlFor={element.id}
                          >
                            {element.value}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-3 col-l-6 col-m-6 col-s-12 search__column">
                <div className="search__criteria vertical">
                  <span className="search__text text-basic">
                    {" "}
                    Pokaż wybrane
                  </span>

                  <div className="horizontal-radio-parent vertical">
                    <ul className="horizontal-radio vertical">
                      <li>
                        <Input
                          refer={this.inputs.free}
                          type="radio"
                          paramName="free"
                          id="only-free"
                          value="only-free"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="only-free"
                        >
                          Wolne
                        </label>
                      </li>
                      <li>
                        <Input
                          refer={this.inputs.promotions}
                          type="radio"
                          paramName="promotions"
                          id="only-prom"
                          value="only-prom"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="only-prom"
                        >
                          Promocje
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-5 col-l-12 col-m-12 col-s-12 search__column">
                <div className="search__criteria vertical">
                  <span className="search__text text-basic">
                    Dobierz cechy mieszkania
                  </span>

                  <div className="horizontal-radio-parent vertical">
                    <ul className="horizontal-radio vertical">
                      <li>
                        <Input
                          refer={this.inputs.garden}
                          type="radio"
                          paramName="garden"
                          id="ogrod"
                          value="ogrod"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="ogrod"
                        >
                          Ogród
                        </label>
                      </li>

                      <li>
                        <Input
                          refer={this.inputs.balcony}
                          type="radio"
                          paramName="balcony"
                          id="balkon"
                          value="balkon"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="balkon"
                        >
                          Balkon
                        </label>
                      </li>

                      <li>
                        <Input
                          refer={this.inputs.wardrobe}
                          type="radio"
                          paramName="wardrobe"
                          id="wardrobe"
                          value="wardrobe"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="wardrobe"
                        >
                          Garderoba
                        </label>
                      </li>

                      <li>
                        <Input
                          refer={this.inputs.loggia}
                          type="radio"
                          paramName="loggia"
                          id="loggia"
                          value="loggia"
                          onlyone={true}
                        />
                        <label
                          className="label__text text-basic"
                          onClick={this.clickRadio}
                          htmlFor="loggia"
                        >
                          Loggia
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "search__actions" +
                (this.state.mobileShowFilters ? " mobile-active" : "")
              }
            >
              <button
                type="submit"
                className="button search__button button-basic"
              >
                Pokaż wyniki
              </button>
              {this.state.params ? (
                <NavLink
                  to="/lista-apartamentow"
                  className="button clean__button button-basic"
                >
                  Resetuj wyniki
                </NavLink>
              ) : null}
            </div>
          </form>
        </div>
      </section>
    );
  }
}
