import React from "react";
import s from "./DlugaSection.scss";
import {NavHashLink as NavLink} from "react-router-hash-link";
import {Link} from "react-router-dom";
import dlugabackground from "../../assets/images/dluga15-exterior-tram.webp";
import dlugabackgroundA from "../../assets/images/dluga15-exterior-tram.jpg";

export default class DlugaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollWithOffset = el => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <section className="lp-dluga-section" id="dluga15">
        <div className="dluga-section__container container-new row">
          <div className="dluga-section__left col-4 col-m-12">
            <h2 className="dluga-section__title text-basic">Długa 15</h2>
            <p className="dluga-section__text text-basic">
            
              Nowoczesne centrum Wrocławia. Wszystko co cenisz w Twoim zasięgu.
            </p>

            <NavLink
              className="dluga-section__button button-basic button-black"
              scroll={el => {
                this.scrollWithOffset(el);
              }}
              onClick={e => {
                if (window && window.location.pathname === "/lokalizacja") {
                  e.preventDefault();
                }
              }}
              exact
              to={"/lokalizacja/#mapa"}
            >
              Zobacz lokalizację
            </NavLink>
          </div>

          <div className="dluga-section__right col-m-12">
            <picture className="dluga__background">
              <source srcset={dlugabackground} type="image/webp" />
              <source srcset={dlugabackgroundA} type="image/jpeg" />
              <img src={dlugabackgroundA} className="dluga__background" />
            </picture>
          </div>
        </div>
      </section>
    );
  }
}
