import React from "react";
import "./RegionSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import region from "../../assets/images/region-background.webp";
import regionA from "../../assets/images/region-background.jpg";
import region1a from "../../assets/images/dluga15-exterior-03-new.jpg";
import region2 from "../../assets/images/region2.webp";
import region2a from "../../assets/images/region2.jpg";

export default class RegionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    gsap.registerPlugin(ScrollTrigger);

    this.imageBottomRight = React.createRef();
    this.imageInside = React.createRef();
  }
  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  componentDidMount = () => {
    const firstImageTL = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: this.imageBottomRight.current,
        scrub: 1,
        markers: false,
      },
    });

    firstImageTL.from(this.imageInside.current, { opacity: 0.9, scale: 1.4 });
    firstImageTL.to(this.imageInside.current, { opacity: 1, scale: 1.3 });
  };

  render() {
    let apartmentsData = [
      { id: 0, title: "50", text: "metrów do przystanku tramwajowego" },
      { id: 1, title: "20", text: "minut spacerem do Rynku" },
      {
        id: 2,
        title: "670",
        text: "metrów od kortów tenisowych (kryte i odkryte)",
      },
      { id: 3, title: "8", text: "minut rowerem do Parku Zachodniego" },
    ];
    return (
      <section className="lp-region">
        <div className="region__container container-new">
          <div className="region__top row">
            <div className="region__top-left col-4 col-m-12">
              <h2 className="region__title text-basic">W okolicy</h2>
              <p className="region__text text-basic">
                Lokalizacja w centrum niesie szereg zalet - jedną z nich jest
                dostępna w okolicy infrastruktura. W promieniu ok kilometra
                można znaleźć sklepy, usługi, szkoły i kościoły.
              </p>

              <NavLink
                className="region__button button-basic button-black"
                scroll={(el) => {
                  this.scrollWithOffset(el);
                }}
                onClick={(e) => {
                  if (window && window.location.pathname === "/lokalizacja") {
                    e.preventDefault();
                  }
                }}
                exact
                to={"/lokalizacja/#mapa"}
              >
                Mapa okolicy
              </NavLink>
            </div>

            <div className="region__top-right col-8 col-m-12">
              <div className="region__right-top-left">
                <picture className="region__background">
                  <source srcset={region} type="image/webp" />
                  <source srcset={regionA} type="image/jpeg" />
                  <img src={regionA} className="region__background" alt="" />
                </picture>
              </div>

              <div className="region__right-top-right">
                {apartmentsData.map((element) => (
                  <div className="region__box" key={"region" + element.id}>
                    <h3 className="region__box-title">{element.title}</h3>
                    <p className="region__box-text">{element.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="region__bottom row">
            <div
              className="region__bottom-left col-8 col-m-12"
              ref={this.imageBottomRight}
            >
              <img
                ref={this.imageInside}
                className="region__background-bigger"
                alt=""
                src={region1a}
              />
            </div>

            <div className="region__bottom-right col-4 col-m-12">
              <picture className="region__background">
                <source srcset={region2} type="image/webp" />
                <source srcset={region2a} type="image/jpeg" />
                <img src={region2a} className="region__background" alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
