import React, { Component } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import s from "./ApartmentView.scss";
import { BrowserRouter, Link } from "react-router-dom";
import ApartmentNewSlick from "../ApartmentNewSlick/";

function numberWithCommas(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(2))
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(",", " ")
    .replace(".", ",");
}

function fixM2(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(2)).replace(
    ".",
    ","
  );
}

function fixM0(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(0));
}

export default class ApartmentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImgNumber: 1,
      maxImageNumber: 0,
    };

    let flat = this.props.params.flatNumber.replace(/\./g, "/");
    let thisFlat = this.props.flats.find((i) => i.flatNumber === flat) || false;

    thisFlat &&
      (((window || {}).a4develop || {}).enterOffer || (() => {}))(thisFlat._id);
  }

  componentWillUnmount() {
    (((window || {}).a4develop || {}).exitOffer || (() => {}))();
  }

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  moveDataUp = (current, total) => {
    if (
      this.state.maxImageNumber != total ||
      this.state.currentImgNumber != current
    ) {
      setTimeout(() => {
        this.setState({
          maxImageNumber: total,
          currentImgNumber: current,
        });
      }, 1);
    }
  };

  render() {
    if (
      !this.props.params &&
      !(
        typeof this.props.params != "undefined" &&
        Object.keys(this.props.params).length > 0
      )
    ) {
      return null;
    } else {
      let flat = this.props.params.flatNumber.replace(/\./g, "/");
      let thisFlat =
        this.props.flats.find((i) => i.flatNumber === flat) || false;
      if (!thisFlat) {
        return null;
      }

      let budNr = thisFlat.flatNumber.split("/")[0];
      let floorNr = thisFlat.flatNumber.split("/")[1];

      //2* pokazują się jako dwupokojowe + dodatkowa infomacja na dole
      let rooms = thisFlat.rooms.slice(1, 2).replace("*", "");

      return (
        <section className="lp-apartment-view" ref={this.props.reference}>
          <div className="apartment-view__container container">
            <h2 className="apartment-view__title title-basic">
              Mieszkanie numer {flat} {thisFlat.rooms.replace("*", "")}
            </h2>

            <div className="apartment-view__data">
              <div className="apartment-data">
                <div className="apartment-data__item">
                  Powierzchnia:
                  <span>
                    {thisFlat.area} m<sup>2</sup>
                  </span>
                </div>
                <div className="apartment-data__item">
                  Status:
                  {thisFlat.flatStatus === 1 ? (
                    <span className="freeStatus"> Wolne</span>
                  ) : thisFlat.flatStatus === 2 ? (
                    <span className="reservationStatus">Rezerwacja</span>
                  ) : thisFlat.flatStatus === 3 ? (
                    <span className="soldStatus">Sprzedane</span>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="apartment-data__item">
                  Cena:
                  <span>
                    {"oldPrice" in thisFlat && thisFlat.oldPrice && (
                      <s>{thisFlat.oldPrice} zł</s>
                    )}
                    {thisFlat.brutto
                      ? thisFlat.flatStatus == 3
                        ? " - "
                        : thisFlat.flatStatus == 2
                        ? " - "
                        : numberWithCommas(thisFlat.brutto) + " zł"
                      : "-"}
                  </span>
                  {/* <NavLink
            scroll={el => {
              this.scrollWithOffset(el);
            }}
            onClick={e => {
              e.preventDefault();
            }}
            exact
            to={"/#kontakt"}
          >
            Zapytaj o cenę
          </NavLink> */}
                </div>
                {/* <div className="apartment-data__item">
                  Cena w promocji: <span>-</span>
                </div> */}

                <div className="apartment-data__item">
                  Kondygnacja:
                  <span>{thisFlat.storey}</span>
                </div>
                <div className="apartment-data__item">
                  Liczba pokoi:
                  <span>{rooms}</span>
                </div>

                {thisFlat.balconyArea ? (
                  <div className="apartment-data__item">
                    Balkon:
                    {fixM0(thisFlat.balconyArea) == 0 ? (
                      fixM0(thisFlat.loggia) == 0 ? (
                        "-"
                      ) : (
                        <span>
                          {fixM2(thisFlat.loggia)} m<sup>2</sup>
                        </span>
                      )
                    ) : (
                      <span>
                        {fixM2(thisFlat.balconyArea)} m<sup>2</sup>
                      </span>
                    )}
                  </div>
                ) : null}

                {thisFlat.gardenArea ? (
                  <div className="apartment-data__item">
                    Ogród:{" "}
                    {fixM0(thisFlat.gardenArea) == 0 ? (
                      "-"
                    ) : (
                      <span>
                        {fixM2(thisFlat.gardenArea)} m<sup>2</sup>
                      </span>
                    )}
                  </div>
                ) : null}
              </div>
              {thisFlat.rooms.includes("*") ? (
                <p className="apartment-view__info text-basic">
                  * Mieszkanie z aneksem sypialnianym
                </p>
              ) : null}
            </div>
            <div className="apartment-view__links">
              <a
                download
                href={
                  "/pdf/" +
                  String(thisFlat.flatNumber).split("/").join("_") +
                  ".pdf"
                }
                className="button-basic apartment-view__link accent"
              >
                Pobierz kartę mieszkania
              </a>

              <NavLink
                className="button-basic apartment-view__link"
                scroll={(el) => {
                  this.scrollWithOffset(el);
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
                exact
                to={"/#kontakt"}
              >
                Zapytaj o mieszkanie
              </NavLink>

              {/* <Link
                to="/spotkanie"
                className="button-basic apartment-view__link"
              >
                Umów spotkanie online
              </Link> */}
              {/* <Link to="/" className="button-basic apartment-view__link">
                Rezerwuj
              </Link> */}
            </div>

            <div className="apartment-view__slider">
              <ApartmentNewSlick thisFlat={thisFlat} />
            </div>
          </div>
        </section>
      );
    }
  }
}
