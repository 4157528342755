import React, { Component } from "react";
import { BrowserRouter, Link, NavLink } from "react-router-dom";
import s from "./ContactForm.scss";
import Input from "./Input";
const uuidv4 = require("uuid/v4");

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.form = {};
    this.cb1 = uuidv4();
    this.cb2 = uuidv4();

    this.state = {
      roomsCount: "",
      room1: false,
      room2: false,
      room3: false,
      room4: false,

      validate: {
        inputName: true,
        inputSurname: true,
        inputPhone: true,
        inputEmail: true,
        inputTexarea: true,
        inputCheckbox1: true,
        inputCheckbox2: true,
      },
      wasSent: false,
      openSent: false,
    };
  }

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  validateInput = (value) => {
    return value.length > 0;
  };

  focusOnFirst = (e) => {
    e.preventDefault();
    this.form.inputName.focus();
  };

  handleRadioChange = (e, val) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  setRoomsCount = () => {
    let arr = [];
    if (this.state.room1) {
      arr.push("1 pokój");
    } else {
      arr.filter((item) => item !== "1 pokój");
    }
    if (this.state.room2) {
      arr.push("2 pokoje");
    } else {
      arr.filter((item) => item !== "2 pokoje");
    }
    if (this.state.room3) {
      arr.push("3 pokoje");
    } else {
      arr.filter((item) => item !== "3 pokoje");
    }
    if (this.state.room4) {
      arr.push("4 pokoje");
    } else {
      arr.filter((item) => item !== "4 pokoje");
    }
    return arr.toString();
  };

  sendForm = (e) => {
    e.preventDefault();

    let pageLocation = window.location.href;
    let offerId = false;

    if (pageLocation == "/") {
      pageLocation = "strona główna";
    }

    if (this.props.params && this.props.params.flatNumber) {
      let idToFind = this.props.params.flatNumber.split(".").join("/");
      let finded = this.props.flats.find(
        (element) => element.flatNumber === idToFind
      );
      offerId = finded._id;
    }

    if (!this.state.wasSent) {
      var validate = {
        inputName: true,
        inputSurname: true,
        inputPhone: true,
        inputEmail: true,
        inputTexarea: true,
        inputCheckbox1: true,
        inputCheckbox2: true,
      };

      var valid = true;

      for (var property in this.form) {
        if (this.form.hasOwnProperty(property)) {
          var element = this.form[property];

          if (element.type == "email") {
            var v = this.validateEmail(element.value);
            if (!v) valid = false;
            validate[property] = v;
          } else if (element.type == "checkbox") {
            var v = element.checked;
            if (property != "inputCheckbox2") {
              if (!v) valid = false;
              validate[property] = v;
            }
          } else if (element.name == "message") {
            validate[property] = true;
          } else {
            var v = this.validateInput(element.value);
            if (!v) valid = false;
            validate[property] = v;
          }
        }
      }

      this.setState({
        validate,
      });

      if (valid) {
        try {
          window.dataLayer.push({ event: "formularz" });
        } catch (e) {}

        let analyticsUserData = false;
        if (this.state.frontendKey && window && "localStorage" in window) {
          if (
            this.state.frontendKey in window.localStorage &&
            window.localStorage[this.state.frontendKey]
          ) {
            analyticsUserData = JSON.parse(
              window.localStorage[this.state.frontendKey]
            );
          }
        }

        if (!(analyticsUserData && "userId" in analyticsUserData)) {
          let userId = ((window || {}).a4develop || {}).getUserId();
          if (!userId) {
            userId = uuidv4();
            (((window || {}).a4develop || {}).setUserId || (() => {}))(userId);
          }

          analyticsUserData = {
            userId,
          };

          if (this.state.frontendKey && window && "localStorage" in window) {
            window.localStorage[this.state.frontendKey] =
              JSON.stringify(analyticsUserData);
          }
        }

        let roomsCount = this.setRoomsCount();

        let catalogData = {
          inputName: this.form.inputName.value,
          inputSurname: this.form.inputSurname.value,
          inputPhone: this.form.inputPhone.value,
          inputEmail: this.form.inputEmail.value,
          inputTexarea: this.form.inputTexarea.value,
          inputCheckbox2: this.form.inputCheckbox2.checked,
          roomsCount: roomsCount,
          formLocation: "footer",
          pageLocation: pageLocation,
          userId: analyticsUserData.userId,
          offerId: offerId,
          uniqId: ((window || {}).a4develop || {}).getUniqId(),
        };

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/contact-form", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(catalogData));
        xhr.onload = () => {
          this.setState({
            wasSent: true,
            openSent: true,
            room1: false,
            room2: false,
            room3: false,
            room4: false,
          });

          for (var property in this.form) {
            if (this.form.hasOwnProperty(property)) {
              this.form[property].value = "";

              if (property === "inputCheckbox1" || "inputCheckbox2") {
                this.form["inputCheckbox1"].checked = false;
                this.form["inputCheckbox2"].checked = false;
              }
            }
          }
        };
      }
    }
  };

  closePopup = () => {
    this.setState({
      openSent: false,
    });
  };

  render() {
    return (
      <div className="lp-contact-form">
        <form className="contact__form" method="post" onSubmit={this.sendForm}>
          <div className="row contact-form__inputs">
            <div className="col-6 col-m-12 contact-form__input-box">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputName ? "" : "error"}
                ref={(input) => {
                  this.form.inputName = input;
                }}
                type="text"
                name="name"
                placeholder="Imię *"
              />
            </div>
            <div className="col-6 col-m-12 contact-form__input-box">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputSurname ? "" : "error"}
                ref={(input) => {
                  this.form.inputSurname = input;
                }}
                type="text"
                name="surname"
                placeholder="Nazwisko *"
              />
            </div>
            <div className="col-6 col-m-12 contact-form__input-box">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputEmail ? "" : "error"}
                ref={(input) => {
                  this.form.inputEmail = input;
                }}
                type="email"
                placeholder={"E-mail *"}
              />
            </div>
            <div className="col-6 col-m-12 ">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputPhone ? "" : "error"}
                ref={(input) => {
                  this.form.inputPhone = input;
                }}
                type="text"
                placeholder={"Telefon *"}
              />
            </div>

            <div className="col-12 contact-form__textarea">
              <textarea
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputTexarea ? "" : "error"}
                ref={(input) => {
                  this.form.inputTexarea = input;
                }}
                name="message"
                placeholder="Wiadomość"
                defaultValue={""}
              />
            </div>

            <div className="col-12 contact-form__radio">
              <p className="radio__text text-basic">
                Jakiego mieszkania szukasz? <span>Podaj liczbę pokoi.</span>
              </p>

              <div className="radio__box">
                <Input
                  name="room1"
                  value="1 pokój"
                  isChecked={this.state.room1}
                  handleRadioChange={this.handleRadioChange}
                />
                <Input
                  name="room2"
                  value="2 pokoje"
                  isChecked={this.state.room2}
                  handleRadioChange={this.handleRadioChange}
                />
                <Input
                  name="room3"
                  value="3 pokoje"
                  isChecked={this.state.room3}
                  handleRadioChange={this.handleRadioChange}
                />
                <Input
                  name="room4"
                  value="4 pokoje"
                  isChecked={this.state.room4}
                  handleRadioChange={this.handleRadioChange}
                />
              </div>
            </div>
            <p className="contact-form__privacy-info">
              Przed wyrażeniem zgód zapoznaj się z informacjami o przetwarzaniu
              danych. Administratorem Państwa danych osobowych jest Hossanova
              Sp. z o.o. Sp.k.{" "}
            </p>
            <div className="col-12 contact-form__agreement-box">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputCheckbox1 ? "" : "error"}
                ref={(input) => {
                  this.form.inputCheckbox1 = input;
                }}
                id={this.cb1}
                type="checkbox"
              />
              <label htmlFor={this.cb1}>
                <span className="af-icon-tick"></span>
              </label>

              <label htmlFor={this.cb1} className="agreement-span">
                Wyrażam zgodę na gromadzenie, przetwarzanie i wykorzystanie
                moich danych osobowych przez firmę Hossanova 2 Sp. z o.o.
              </label>
            </div>
            <div className="col-12 contact-form__agreement-box ">
              <input
                disabled={this.state.wasSent ? "disabled" : ""}
                className={this.state.validate.inputCheckbox2 ? "" : "error"}
                ref={(input) => {
                  this.form.inputCheckbox2 = input;
                }}
                id={this.cb2}
                type="checkbox"
              />
              <label htmlFor={this.cb2}>
                <span className="af-icon-tick"></span>
              </label>

              <label htmlFor={this.cb2} className="agreement-span">
                Wyrażam zgodę na otrzymywanie informacji handlowych od firmy
                Hossanova 2 Sp. z o.o..
              </label>
            </div>
          </div>
          <span className="required-info">* Pola wymagane</span>

          <div className="form__button-box">
            <button className="form__button button-basic">
              Wyślij wiadomość
            </button>
          </div>
        </form>

        {this.state.wasSent && this.state.openSent ? (
          <div className="form__sent-box">
            <div className="form__sent">
              <span
                onClick={this.closePopup}
                className="form__sent-close text-basic"
              >
                Zamknij
              </span>
              <h4 className="form__sent-info text-basic">
                Formularz został wysłany.
              </h4>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
