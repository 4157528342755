import React from "react";

import gallery1 from "../../assets/images/dziennik-budovy/april-2022/apr1.jpeg";
import gallery2 from "../../assets/images/dziennik-budovy/april-2022/apr2.jpeg";
import gallery3 from "../../assets/images/dziennik-budovy/april-2022/apr3.jpeg";
import gallery4 from "../../assets/images/dziennik-budovy/april-2022/apr4.jpeg";
import gallery5 from "../../assets/images/dziennik-budovy/april-2022/apr5.jpeg";
import gallery6 from "../../assets/images/dziennik-budovy/april-2022/apr6.jpeg";
import gallery7 from "../../assets/images/dziennik-budovy/april-2022/apr7.jpeg";

import mayImage1 from "../../assets/images/dziennik-budovy/may-2022/may1.jpg";
import mayImage2 from "../../assets/images/dziennik-budovy/may-2022/may2.jpg";
import mayImage3 from "../../assets/images/dziennik-budovy/may-2022/may3.jpg";
import mayImage4 from "../../assets/images/dziennik-budovy/may-2022/may4.jpg";
import mayImage5 from "../../assets/images/dziennik-budovy/may-2022/may5.jpg";
import mayImage6 from "../../assets/images/dziennik-budovy/may-2022/may6.jpg";
import mayImage7 from "../../assets/images/dziennik-budovy/may-2022/may7.jpg";

import sepImage1 from "../../assets/images/dziennik-budovy/september-2022/sep1.jpg";
import sepImage2 from "../../assets/images/dziennik-budovy/september-2022/sep2.jpg";
import sepImage3 from "../../assets/images/dziennik-budovy/september-2022/sep3.jpg";
import sepImage4 from "../../assets/images/dziennik-budovy/september-2022/sep4.jpg";
import sepImage5 from "../../assets/images/dziennik-budovy/september-2022/sep5.jpg";
import sepImage6 from "../../assets/images/dziennik-budovy/september-2022/sep6.jpg";
import sepImage7 from "../../assets/images/dziennik-budovy/september-2022/sep7.jpg";
import sepImage8 from "../../assets/images/dziennik-budovy/september-2022/sep8.jpg";
import sepImage9 from "../../assets/images/dziennik-budovy/september-2022/sep9.jpg";
import sepImage10 from "../../assets/images/dziennik-budovy/september-2022/sep10.jpg";

import mar23gallery1 from "../../assets/images/dziennik-budovy/march-2023/feb23-1.jpg";
import mar23gallery2 from "../../assets/images/dziennik-budovy/march-2023/feb23-2.jpg";
import mar23gallery3 from "../../assets/images/dziennik-budovy/march-2023/feb23-3.jpg";
import mar23gallery4 from "../../assets/images/dziennik-budovy/march-2023/feb23-4.jpg";
import mar23gallery5 from "../../assets/images/dziennik-budovy/march-2023/feb23-5.jpg";
import mar23gallery6 from "../../assets/images/dziennik-budovy/march-2023/feb23-6.jpg";

import may23gallery1 from "../../assets/images/dziennik-budovy/may-2023/may1.jpg";
import may23gallery2 from "../../assets/images/dziennik-budovy/may-2023/may2.jpg";
import may23gallery3 from "../../assets/images/dziennik-budovy/may-2023/may3.jpeg";
import may23gallery4 from "../../assets/images/dziennik-budovy/may-2023/may4.JPG";
import may23gallery5 from "../../assets/images/dziennik-budovy/may-2023/may5.JPG";
import may23gallery6 from "../../assets/images/dziennik-budovy/may-2023/may6.JPG";
import may23gallery7 from "../../assets/images/dziennik-budovy/may-2023/may7.JPG";
import may23gallery8 from "../../assets/images/dziennik-budovy/may-2023/may8.JPG";
import may23gallery9 from "../../assets/images/dziennik-budovy/may-2023/may9.JPG";
import may23gallery10 from "../../assets/images/dziennik-budovy/may-2023/may10.JPG";
import may23gallery11 from "../../assets/images/dziennik-budovy/may-2023/may11.JPG";
import may23gallery12 from "../../assets/images/dziennik-budovy/may-2023/may12.JPG";
import may23gallery13 from "../../assets/images/dziennik-budovy/may-2023/may13.JPG";
import may23gallery14 from "../../assets/images/dziennik-budovy/may-2023/may14.JPG";
import may23gallery15 from "../../assets/images/dziennik-budovy/may-2023/may15.JPG";
import may23gallery16 from "../../assets/images/dziennik-budovy/may-2023/may16.jpg";
import may23gallery17 from "../../assets/images/dziennik-budovy/may-2023/may17.JPG";
import may23gallery18 from "../../assets/images/dziennik-budovy/may-2023/may18.JPG";
import may23gallery19 from "../../assets/images/dziennik-budovy/may-2023/may19.JPG";
import may23gallery20 from "../../assets/images/dziennik-budovy/may-2023/may20.JPG";
import may23gallery21 from "../../assets/images/dziennik-budovy/may-2023/may21.JPG";
import may23gallery22 from "../../assets/images/dziennik-budovy/may-2023/may22.JPG";
import may23gallery23 from "../../assets/images/dziennik-budovy/may-2023/may23.JPG";
import may23gallery24 from "../../assets/images/dziennik-budovy/may-2023/may24.JPG";

import icon from "../../assets/icons/accent.svg";
import "./GalleryConstruction.scss";
import { NavLink } from "react-router-dom";

export default class GalleryConstruction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
      image: false,
      id: 0,
      rotate: 0,
    };

    this.photos = [
      { id: 31, title: "", image: may23gallery1, header: `Maj 2023` },
      { id: 32, title: "", image: may23gallery2 },
      { id: 33, title: "", image: may23gallery3 },
      { id: 34, title: "", image: may23gallery4 },
      { id: 35, title: "", image: may23gallery5 },
      { id: 38, title: "", image: may23gallery8 },
      { id: 36, title: "", image: may23gallery6 },
      { id: 37, title: "", image: may23gallery7 },
      { id: 39, title: "", image: may23gallery9 },
      { id: 40, title: "", image: may23gallery10 },
      { id: 41, title: "", image: may23gallery11 },
      { id: 42, title: "", image: may23gallery12 },
      { id: 43, title: "", image: may23gallery13 },
      { id: 44, title: "", image: may23gallery14 },
      { id: 45, title: "", image: may23gallery15 },
      { id: 46, title: "", image: may23gallery16 },
      { id: 47, title: "", image: may23gallery17 },
      { id: 48, title: "", image: may23gallery18 },
      { id: 49, title: "", image: may23gallery19 },
      { id: 50, title: "", image: may23gallery20 },
      { id: 51, title: "", image: may23gallery21 },
      { id: 52, title: "", image: may23gallery22 },
      { id: 53, title: "", image: may23gallery23 },
      { id: 53, title: "", image: may23gallery24 },
      { id: 25, title: "", image: mar23gallery1,  header: `Kwiecień 2023`  },
      { id: 26, title: "", image: mar23gallery2 },
      { id: 27, title: "", image: mar23gallery3 },
      { id: 28, title: "", image: mar23gallery4 },
      { id: 29, title: "", image: mar23gallery5 },
      { id: 30, title: "", image: mar23gallery6 },
      { id: 14, title: "", image: sepImage1, header: `Wrzesień 2022` },
      { id: 15, title: "", image: sepImage2 },
      { id: 16, title: "", image: sepImage3 },
      { id: 17, title: "", image: sepImage4 },
      { id: 18, title: "", image: sepImage5 },
      { id: 19, title: "", image: sepImage6 },
      { id: 20, title: "", image: sepImage7 },
      { id: 21, title: "", image: sepImage8 },
      { id: 22, title: "", image: sepImage9 },
      { id: 23, title: "", image: sepImage10 },
      { id: 7, title: "", image: mayImage1, header: `Maj 2022` },
      { id: 8, title: "", image: mayImage2 },
      { id: 9, title: "", image: mayImage3 },
      { id: 10, title: "", image: mayImage4 },
      { id: 11, title: "", image: mayImage5 },
      { id: 12, title: "", image: mayImage6 },
      { id: 13, title: "", image: mayImage7 },
      { id: 0, title: "", image: gallery1, header: `Kwiecień 2022` },
      { id: 1, title: "", image: gallery2 },
      { id: 2, title: "", image: gallery3 },
      { id: 3, title: "", image: gallery4 },
      { id: 4, title: "", image: gallery5 },
      { id: 5, title: "", image: gallery6 },
      { id: 6, title: "", image: gallery7 },
    ];
    this.id = 0;
    this.previewPhotos = [];
  }

  openImage = (index, element) => {
    this.setState({ isOpen: true, image: element, id: index });

    this.id = index;
  };
  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.closeImage();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.escFunction);
    window.scrollTo(0, 0);
  };
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction);
  };

  closeImage = () => {
    this.setState({ isOpen: false });
  };

  seeNextImage = () => {
    let photos = this.photos;

    this.setState({
      rotate: 0,
    });
    if (this.id === photos.length - 1) {
      this.id = 0;
    } else {
      this.id = this.id + 1;
    }
  };
  seePreviousImage = () => {
    let photos = this.photos;

    this.setState({
      rotate: 0,
    });
    if (this.id === 0) {
      this.id = photos.length - 1;
    } else {
      this.id = this.id - 1;
    }
  };

  sortFunction = (a, b) => {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateA < dateB ? 1 : -1;
  };

  render() {
    let previewPhotos = this.photos.slice(0, 100);
    this.previewPhotos = previewPhotos;

    return (
      <section className="lp-gallery">
        <div className="container gallery_container">
          <h2 className="gallery__title title-basic">Dziennik budowy</h2>
          <img
            src={icon}
            alt="icon"
            className="af-icon-accent gallery__icon"
          ></img>

          <div className="gallery__main row">
            {previewPhotos.map((element, index) => (
              <React.Fragment>
                {element.header ? (
                  <p className="gallery__text text-basic">{element.header}</p>
                ) : (
                  ""
                )}
                <div
                  key={"small" + element.id}
                  className="gallery-image-box col-6 col-s-12"
                >
                  <img
                    onClick={() => this.openImage(index, element)}
                    className="gallery-image"
                    src={element.image}
                    alt={element.title}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
          <NavLink to="/apartamenty" className="button-basic gallery__button">
            Wybierz mieszkanie
          </NavLink>
        </div>

        {this.state.isOpen ? (
          <div className="image__container-outside">
            <div className="image__container">
              <div className="image__container-inside">
                <button
                  onClick={() => this.closeImage()}
                  className="button-basic gallery-button gallery-button-close"
                >
                  <span className="af-icon-times-solid" />
                </button>

                <button
                  onClick={() => this.seePreviousImage()}
                  className="button-basic gallery-button gallery-button-left "
                >
                  <span className="af-icon-angle-left-solid"></span>
                </button>

                {this.photos.map((element, index) =>
                  index === this.id ? (
                    <img
                      key={"full" + element.id}
                      className="gallery-image"
                      src={element.image}
                      alt={element.title}
                    ></img>
                  ) : null
                )}

                <button
                  onClick={() => this.seeNextImage()}
                  className="button-basic gallery-button gallery-button-right"
                >
                  <span className="af-icon-angle-right-solid"></span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}
