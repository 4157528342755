import React from "react";
import "./About.scss";

import icon from "../../assets/icons/accent.svg";

export default class About extends React.Component {
  render() {
    return (
      <section
        id='about'
        className="lp-about"
        style={
          this.props.background === "color"
            ? {backgroundColor: "#EDEDEB"}
            : {backgroundColor: "#ffffff"}
        }
      >
        <div className="about__container container">
          <h2 className="about__title title-basic">{this.props.title}</h2>
          <img
            src={icon}
            alt="icon"
            className="af-icon-accent about__icon"
          ></img>
  
          <p className="text-basic about__text">{this.props.text}</p>

        </div>
      </section>
    );
  }
}
