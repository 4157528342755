import React from "react";
import "./History.scss";
import icon from "../../assets/icons/accent.svg";

export default class History extends React.Component {
  render() {
    return (
      <section className="lp-history">
        <div className="history__container container">
          <h2 className="history__title title-basic">Historia ul. Długiej</h2>
          <img
            src={icon}
            alt="icon"
            className="af-icon-accent history__icon"
          ></img>

          <div className="history__main row">
            <div className="history__left col-6 col-m-12">
              <p className="history__text text-basic">
                <span className='history__accent'>1945</span>
                Zniszczenia wojenne z 1945 roku spowodowały zniknięcie znacznej
                części zabudowy ulicy, zachowały się jednak m.in. XIX-wieczny
                spichlerz (na fotografii obok, posesja nr 14/16 przy początkowym
                odcinku ulicy Długiej włączonym obecnie do ul. Rybackiej) i
                budynek administracji.
              </p>
            </div>

            <div className="history__right col-6 col-m-12">
              <p className="history__text text-basic">
                Tereny dawnego poligonu przy ul. Długiej przeznaczono po wojnie
                częściowo pod ogródki działkowe, a część zajmuje stacja
                elektroenergetyczna. Na terenie wybudowanego w latach 80. XIX
                wieku browaru Kipkego po wojnie znajdowały się zakłady urządzeń
                komunalnych.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
