import React from "react";
import "./ApartmentsPage.scss";
import BuildingsBanner from "../BuildingsBanner/";
import FloorView from "../FloorView/";
import ContactSection from "../ContactSection";
import ApartmentView from "../ApartmentView/";

export default class ApartmentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.floorRef = React.createRef();
    this.apartmentRef = React.createRef();
    this.scrollInfo = null;
  }

  scrollWithOffset = el => {
    const elementPosition = el.offsetTop;
    window.scroll({
      top: elementPosition,
      left: 0,
    });
  };

  componentDidMount = () => {

    if (
      this.props.match.params.buildingNumber === "K4" ||
      this.props.match.params.buildingNumber === "K5"
    ) {
      this.props.redirectTo && this.props.redirectTo(`/`);
    } else if (
      this.props &&
      this.props.match.params.storey &&
      this.props.match.params.flatNumber
    ) {
      this.scrollInfo = setTimeout(() => {
        this.scrollWithOffset(this.apartmentRef.current);
      }, 1);
    } else if (this.props && this.props.match.params.storey) {
      this.scrollInfo = setTimeout(() => {
        this.scrollWithOffset(this.floorRef.current);
      }, 1);
    } else {
      window.scrollTo(0, 0);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      if (
        nextProps.match.params.buildingNumber === "K4" ||
        nextProps.match.params.buildingNumber === "K5"
      ) {
        this.props.redirectTo && this.props.redirectTo(`/`);
      } else if (
        nextProps &&
        nextProps.match.params.storey &&
        nextProps.match.params.flatNumber
      ) {
        this.scrollInfo = setTimeout(() => {
          this.scrollWithOffset(this.apartmentRef.current);
        }, 1);
      } else if (nextProps && nextProps.match.params.storey) {
        this.scrollInfo = setTimeout(() => {
          this.scrollWithOffset(this.floorRef.current);
        }, 1);
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.scrollInfo);
  };

  render() {
    return (
      <div className="lp-apartments-page">
        <BuildingsBanner
          params={this.props.match.params ? this.props.match.params : false}
          redirectTo={this.props.redirectTo}
        />

        {this.props && this.props.match.params.storey ? (
          <FloorView
            params={this.props.match.params ? this.props.match.params : false}
            redirectTo={this.props.redirectTo}
            reference={this.floorRef}
          />
        ) : null}
        {this.props &&
        this.props.match.params.storey &&
        this.props.match.params.flatNumber ? (
          <ApartmentView
            params={this.props.match.params ? this.props.match.params : false}
            redirectTo={this.props.redirectTo}
            reference={this.apartmentRef}
          />
        ) : null}

        <ContactSection
          params={this.props.match.params ? this.props.match.params : false}
        />
      </div>
    );
  }
}
