import React from "react";
import "./MapSection.scss";
import GoogleMap from "../GoogleMap/";
import MapIcon from "./MapIcon";

export default class MapSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allActive: false,
      mapPins: [
        {
          icon: "bank",
          text: "Banki",
          active: false,
        },
        {
          icon: "beauty",
          text: "Beauty",
          active: false,
        },
        {
          icon: "recreation",
          text: "Rekreacja",
          active: false,
        },
        {
          icon: "health-service",
          text: "Służba zdrowia",
          active: false,
        },
        {
          icon: "culture",
          text: "Kultura",
          active: false,
        },

        {
          icon: "playground",
          text: "Place zabaw",
          active: false,
        },

        {
          icon: "education",
          text: "Edukacja",
          active: false,
        },
        {
          icon: "nursery",
          text: "Żłobki",
          active: false,
        },
        {
          icon: "church",
          text: "Kościoły",
          active: false,
        },
        {
          icon: "pharmacy",
          text: "Apteki",
          active: false,
        },

        {
          icon: "shop",
          text: "Sklepy",
          active: false,
        },
        {
          icon: "pub",
          text: "Restauracje",
          active: false,
        },
      ],
    };
  }

  toggleAllActive = () => {
    this.setState(
      (state) => ({
        allActive: true,
        mapPins: state.mapPins.map((element) => {
          element.active = true;
          return element;
        }),
      })
    );
  };

  toggleAllDeactivate = () => {
    this.setState(
      (state) => ({
        allActive: false,
        mapPins: state.mapPins.map((element) => {
          element.active = false;
          return element;
        }),
      })
    );
  };

  toggleOneActive = (choosen) => {
    this.setState(
      (state) => ({
        mapPins: state.mapPins.map((element) => {
          if (element.icon == choosen) {
            element.active = !element.active;
          }
          return element;
        }),
      })
    );
  };

  render() {
    return (
      <section className="lp-map-section" id="mapa">
        <div className="map-section__container container">
          <h2 className='map-section__title title-basic'>Poznaj okolicę</h2>
          <div className="map-section__top">
        
            <ul className="map-section__list row">
              {this.state.mapPins.map((element,index) => (
                <MapIcon
                key={'mapIcon'+index}
                  element={element}
                  toggleOneActive={this.toggleOneActive}
                />
              ))}
            </ul>

            {this.state.allActive ? (
              <span
                className="map-section__select button-basic"
                onClick={() => this.toggleAllDeactivate()}
              >
                Odznacz wszystkie
              </span>
            ) : (
              <span
                className="map-section__select button-basic"
                onClick={() => this.toggleAllActive()}
              >
                Zaznacz wszystkie
              </span>
            )}
          </div>
        </div>

        <div className="map-section__box">
          <GoogleMap mapPins={this.state.mapPins} />
        </div>
      </section>
    );
  }
}
