import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import s from "./Header.scss";
import ScrollEvent from "react-onscroll";
import logo from "../../assets/images/logo-dluga-white.svg";
import MeetingLabel from "../MeetingLabel/";
import HeaderSideContact from "../HeaderSideContact/";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      above0: false,
      showMenu: false,
    };
  }

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  handleScrollCallback = (e) => {
    this.setState({
      above0: !!window.scrollY,
    });
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    let blackMenu =
      (this.props.location &&
        this.props.location.pathname === "/polityka-prywatnosci") ||
      (this.props.location &&
        this.props.location.pathname.includes("/apartamenty")) ||
      (this.props.location && this.props.location.pathname === "/galeria") ||
      (this.props.location &&
        this.props.location.pathname === "/dziennik-budowy") ||
      (this.props.location &&
        this.props.location.pathname === "/lista-apartamentow") ||
      (this.props.location && this.props.location.pathname === "/spotkanie") ||
      (this.props.location &&
        this.props.location.pathname.includes("/znajdz-apartamenty"));

    blackMenu = this.props.location.pathname === "/wyszukiwarka" || blackMenu;

    let whiteMenu = false;

    return (
      <header
        className={
          blackMenu
            ? "lp-header black"
            : whiteMenu
            ? "lp-header white"
            : "lp-header" + (this.state.above0 > 0 ? " header-moving" : "")
        }
      >
        <Link
          to="/apartamenty"
          className={
            "nav__toApartments button-basic" +
            (this.state.above0 > 0 ? " active" : "")
          }
        >
          <span className="af-icon-building icon"></span>
          <span className="text">Wybierz mieszkanie</span>
        </Link>

        <HeaderSideContact closeMenu={this.closeMenu} />

        <nav
          className={
            "header__navigation" + (this.state.showMenu ? " active" : "")
          }
        >
          <div className="navigation__container">
            <ul className="nav__list">
              <li className="nav__list-item">
                <div className="nav__list-item-box">
                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__link"
                    to="/inwestycja"
                  >
                    Inwestycja
                  </NavLink>

                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/galeria"
                  >
                    Galeria
                  </NavLink>

                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/dziennik-budowy"
                  >
                    Dziennik budowy
                  </NavLink>

                  <a
                      onClick={this.closeMenu}
                      className="nav__sublink"
                      href="https://hossanova.pl/wykonczenie-pod-klucz/"
                  >
                    Pod klucz
                  </a>
                </div>
              </li>
              <li className="nav__list-item">
                <div className="nav__list-item-box">
                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__link"
                    to="/lokalizacja"
                  >
                    Lokalizacja
                  </NavLink>

                  <NavLink
                    className="nav__sublink"
                    scroll={(el) => {
                      this.scrollWithOffset(el);
                    }}
                    onClick={(e) => {
                      if (
                        window &&
                        window.location.pathname === "/lokalizacja"
                      ) {
                        e.preventDefault();
                      }
                      this.closeMenu();
                    }}
                    exact
                    to={"/lokalizacja/#mapa"}
                  >
                    Mapa
                  </NavLink>
                </div>
              </li>
              <li className="nav__list-item">
                <div className="nav__list-item-box">
                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__link"
                    to="/apartamenty"
                  >
                    Mieszkania
                  </NavLink>

                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/apartamenty"
                  >
                    Znajdź mieszkanie
                  </NavLink>

                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/lista-apartamentow"
                  >
                    Lista mieszkań
                  </NavLink>

                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/wyszukiwarka"
                  >
                    Wyszukiwarka 3D
                  </NavLink>
                </div>
              </li>
              {/* <li className="nav__list-item">
                <div className="nav__list-item-box">
                  <NavLink
                    onClick={this.closeMenu}
                    className="nav__link"
                    to="/"
                  >
                    Aktualności
                  </NavLink>
                </div>
              </li> */}
              <li className="nav__list-item">
                <div className="nav__list-item-box">
                  <NavLink
                    className="nav__link"
                    scroll={(el) => {
                      this.scrollWithOffset(el);
                    }}
                    onClick={(e) => {
                      if (window && window.location.pathname === "/") {
                        e.preventDefault();
                      }
                      this.closeMenu();
                    }}
                    exact
                    to={"/#kontakt"}
                  >
                    Kontakt
                  </NavLink>
                  <a
                    href="https://hossanova.pl/poznaj-nasze-osiedla/nasze-inwestycje/"
                    target="_blank"
                    onClick={this.closeMenu}
                    className="nav__sublink"
                    to="/"
                  >
                    O inwestorze
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <div className="header__container container row">
          <div className="header-left__col col-4 col-m-2">
            <button className={"header__menu-button"} onClick={this.toggleMenu}>
              {this.state.showMenu ? (
                <span className="af-icon-times-solid header__menu" />
              ) : (
                <span className="af-icon-bars-solid header__menu" />
              )}
              <span
                className={
                  "header__menu-text" + (this.state.showMenu ? " active" : "")
                }
              >
                Zamknij
              </span>
            </button>
          </div>
          <div className="header-main__col col-4 col-m-8">
            <NavLink className="header__logo-box" to={"/"} exact>
              <img src={logo} className="header__logo" alt="logo" />
            </NavLink>
          </div>
          <div className="header-right__col col-4 col-m-2">
            <ul className="header__nav">
              <li className="header__nav-item">
                <NavLink
                  className="header__link text-basic"
                  to={"/lista-apartamentow"}
                >
                  Lista mieszkań
                </NavLink>
              </li>
              <li className="header__nav-item">
                <NavLink
                  className="header__link text-basic"
                  to={"/apartamenty"}
                >
                  Znajdź mieszkanie
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <ScrollEvent handleScrollCallback={this.handleScrollCallback} />
      </header>
    );
  }
}
