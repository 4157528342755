import React from "react";

import "./Home.scss";
import ContactSection from "../ContactSection/";
import HomeBanner from "../HomeBanner";
import DlugaSection from "../DlugaSection/";
import CentrumSection from "../CentrumSection/";
import RadiusSection from "../RadiusSection/";
import InvestmentSection from "../InvestmentSection/";
import BusinessSection from "../BusinessSection/";
import TimeSection from "../TimeSection/";
import ApartmentsNewSection from "../ApartmentsNewSection/";
import EstateNew from "../EstateNew/";
import SpaceSection from "../SpaceSection/";
import RegionSection from "../RegionSection/";
import JournalSection from "../JournalSection/";

export default class Home extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className="lp-home">
        <HomeBanner />

        <DlugaSection />
        <ApartmentsNewSection />

        <CentrumSection />
        <RadiusSection />
        <InvestmentSection />
        <BusinessSection />
        <EstateNew />

        <SpaceSection />
        <RegionSection />
        <TimeSection />
        {/* <JournalSection /> */}

        <ContactSection />
      </div>
    );
  }
}
