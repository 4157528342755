import React from "react";
import s from "./HeaderSideContact.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";

export default class HeaderSideContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="header__side-contact">
        <a href="tel:0048717261357" className="side__link">
          <span className="af-icon-phone side__icon"></span>
        </a>
        <NavLink
          className="side__link"
          smooth
          scroll={(el) => {
            this.scrollWithOffset(el);
          }}
          onClick={(e) => {
            if (window.location.pathname == "/") {
              e.preventDefault();
            }
            this.props.closeMenu();
          }}
          to={"/#kontakt"}
        >
          <span className="af-icon-mail side__icon"></span>
        </NavLink>
        <a
          href="https://www.facebook.com/ProjektyDeweloperskieHossanova/"
          target="_blank"
          className="side__link"
        >
          <span className="af-icon-facebook side__icon"></span>
        </a>
      </div>
    );
  }
}
