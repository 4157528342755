import React from "react";
import "./MapSection.scss";

export default class MapIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  toggleActive = (choosen) => {
    this.props.toggleOneActive(choosen);
  };

  render() {
    return (
      <li
        className={
          "map-section__list-item col-2 col-m-3 col-s-6" +
          (this.props.element.active ? " active" : "")
        }
        onClick={() => this.toggleActive(this.props.element.icon)}
      >
        <div className="icon-box">
          <span
            className={
              `af-icon-${this.props.element.icon}` +
              (this.props.element.active ? " active" : "")
            }
          ></span>
        </div>
        {this.props.element.text}
      </li>
    );
  }
}
