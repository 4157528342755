import React from "react";
import s from "./BuildingsBanner.scss";
import {NavHashLink as NavLink} from "react-router-hash-link";
import {Redirect, withRouter} from "react-router-dom";

import backgroundNew from "../../assets/images/dluga15-S.webp";
import backgroundNewA from "../../assets/images/dluga15-S.jpg";
import mapper from "../../assets/images/buildings-render-map.svg";

import SVG from "svg.js";
import BuildingsBannerLabel from "../BuildingsBannerLabel/";
import BuildingLabel from "../BuildingLabel/";
const uuidv4 = require("uuid/v4");

class BuildingsBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingNumber: "",
      storey: "",
      hoveredBuilding: false,
    };

    this.svgParent = React.createRef();
    this.uuid = `svg-${uuidv4()}`;
    this.draw = false;
    this.lastSvgName = null;
    this.svgCallbacks = [];

    this.floorsTypes = [
      "L_P0",
      "L_P1",
      "L_P2",
      "L_P2",
      "L_P4",
      "T_P0",
      "T_P1",
      "T_P2",
      "T_P2",
      "T_P4",
      "T_P5",
      "T_P6",
    ];

    this.inactiveFloorsTypes = [
      "C_P0",
      "C_P1",
      "C_P2",
      "C_P3",
      "C_P4",
      "C_P5",
      "C_P6",
      "C_P7",
      "C_P8",
    ];
  }

  componentDidMount = () => {
    if (this.props !== "undefined" && Object.keys(this.props).length > 0) {
      this.setState({
        buildingNumber: this.props.params.buildingNumber,
        storey: this.props.params.storey,
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      if (nextProps !== "undefined" && Object.keys(nextProps).length > 0) {
        this.setState({
          buildingNumber: nextProps.params.buildingNumber,
          storey: nextProps.params.storey,
        });
      }
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const svgName = `Budynek${this.convertBuildingNumber(
      this.state.buildingNumber
    )}_${this.state.storey}`;
    !this.lastSvgName && this.lastSvgName != svgName && this.makeSVG(svgName);
  }

  convertBuildingNumber = num => {
    // K1 to budynek L a K2 i K3 to budynek T
    let buildingNumber;
    if (num === "K1") {
      buildingNumber = "L";
    } else if (num === "K2" || num === "K3") {
      buildingNumber = "T";
    } else if (num === "K4" || num === "K5") {
      buildingNumber = "C";
    }
    return buildingNumber;
  };

  makeSVG = svgName => {
    var ajax = new XMLHttpRequest();
    ajax.open("GET", mapper, true);
    ajax.send();
    ajax.onload = e => {
      this.draw = SVG(this.uuid);
      let draw = this.draw;

      this.lastSvgName = svgName;

      draw.svg(ajax.responseText);

      let item = draw.select(`path`);
      item.style({fill: "transparent"});

      //C building inactive
      this.inactiveFloorsTypes.forEach(el => {
        let cItem = draw.select(`#${el}`);
        cItem.style({fill: "#707070", opacity: 0.7, cursor: "not-allowed"});
      });

      item.mouseover(e => {
        let building = e.target.id;

        if (!building.includes("C")) {
          this.setState({
            hoveredBuilding: building,
            labelX: e.clientX,
            labelY: e.clientY,
          });

          draw.select("#" + e.target.id).style({
            fill: "#fff",
            opacity: 0.5,
            cursor: "pointer",
            transition: "0.3s",
          });
        }
      });

      item.mouseout(e => {
        let building = e.target.id;

        if (!building.includes("C")) {
          this.setState({hoveredBuilding: false});

          if (this.state.buildingNumber && this.state.storey) {
            let idToCheck = `${this.convertBuildingNumber(
              this.state.buildingNumber
            )}_${this.state.storey}`;

            if (idToCheck === e.target.id) {
              draw.select("#" + e.target.id).style({
                fill: "#fff",
                opacity: 0.7,
              });
            } else {
              draw.select("#" + e.target.id).style({
                fill: "transparent",
              });
            }
          } else {
            draw.select("#" + e.target.id).style({
              fill: "transparent",
            });
          }
        }
      });

      item.click(e => {
        let id = e.target.id;
        let building = id.split("_")[0];
        let storey = id.split("_")[1];

        if (!building.includes("C")) {
          if (building === "L") {
            building = "K1";
          } else if (building === "T") {
            building = "K2";
          } else if (building === "C") {
            building = "K4";
          }

          // this.props.history.push(`/apartamenty/${building}/${storey}/`);
          this.props.redirectTo !== undefined &&
            this.props.redirectTo &&
            this.props.redirectTo(`/apartamenty/${building}/${storey}/`);

          this.colorBuilding(building, storey);
        }
      });

      this.colorBuilding(this.state.buildingNumber, this.state.storey);

      this.svgCallbacks.forEach(cb => {
        cb();
      });
      this.svgCallbacks = [];
    };
  };

  clearAll = () => {
    let element = this.draw.select(`path`);
    element.style({
      stroke: "transparent",
      "stroke-width": 0,
      fill: "transparent",
    });
  };

  colorBuilding = (buildingNum, storeyNum) => {
    if (this.draw) {
      if (buildingNum && storeyNum) {
        this.clearAll();
        let building = this.draw.select(
          `#${this.convertBuildingNumber(this.state.buildingNumber)}_${
            this.state.storey
          }`
        );
        building.style({
          fill: "#fff",
          opacity: 0.7,
        });

        //C building inactive
        this.inactiveFloorsTypes.forEach(el => {
          let cItem = this.draw.select(`#${el}`);
          cItem.style({fill: "#707070", opacity: 0.7, cursor: "not-allowed"});
        });
      }
    } else {
      this.svgCallbacks.push(() => {
        this.colorBuilding(buildingNum, storeyNum);
      });
    }
  };

  render() {
    return (
      <section className="lp-buildings-banner">
        <div className="buildings-banner__wrapper">
          <BuildingLabel buildingNumber={"T"} isT={true} />
          <BuildingLabel buildingNumber={"L"} isT={false} />

          {this.state.hoveredBuilding ? (
            <BuildingsBannerLabel
              hoveredBuilding={this.state.hoveredBuilding}
              labelX={this.state.labelX}
              labelY={this.state.labelY}
            />
          ) : null}
          <img className="buildings-banner__image" src={backgroundNewA} alt="" />
          <div
            key={"key2" + this.uuid}
            ref={this.svgParent}
            className="buildings-banner__mapper"
            id={this.uuid}
          />
        </div>
      </section>
    );
  }
}

export default withRouter(BuildingsBanner);
