import React from "react";
import s from "./JournalSection.scss";
import {NavHashLink as NavLink} from "react-router-hash-link";
import {Link} from "react-router-dom";
import journal from "../../assets/images/journal-section.webp";
import journala from "../../assets/images/journal-section.jpg";

import journal1 from "../../assets/images/journal-section1.webp";
import journal1a from "../../assets/images/journal-section1.jpg";

import journal2 from "../../assets/images/journal-section2.webp";
import journal2a from "../../assets/images/journal-section2.jpg";

import journal4 from "../../assets/images/journal-section.webp";
import journal4a from "../../assets/images/journal-section.jpg";

export default class JournalSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="lp-journal">
        <div className="journal__container container-new">
          <div className="journal__top row">
            <div className="journal__top-left col-4 col-m-12">
              <h2 className="journal__title text-basic">Dziennik budowy</h2>
              <p className="journal__text text-basic">
                Zobacz galerię dokumentującą postępy budowy.
              </p>

              <Link
                to="/lista-apartamentow"
                className="journal__button button-basic button-black"
              >
                Zobacz więcej
              </Link>
            </div>

            <div className="journal__top-right col-8 col-m-12">
              <div className="journal__right-top-left">
                <picture className="journal__background">
                  <source srcset={journal} type="image/webp" />
                  <source srcset={journala} type="image/jpeg" />
                  <img src={journala} className="journal__background" />
                </picture>

                <picture className="journal__background">
                  <source srcset={journal2} type="image/webp" />
                  <source srcset={journal2a} type="image/jpeg" />
                  <img src={journal2a} className="journal__background" />
                </picture>

               
              </div>

              <div className="journal__right-top-right">
           
                <picture className="journal__background">
                  <source srcset={journal1} type="image/webp" />
                  <source srcset={journal1a} type="image/jpeg" />
                  <img src={journal1a} className="journal__background" />
                </picture>

                <picture className="journal__background">
                  <source srcset={journal4} type="image/webp" />
                  <source srcset={journal4a} type="image/jpeg" />
                  <img src={journal4a} className="journal__background" />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
