import React, {Component} from "react";
import s from "./BuildingLabel.scss";

export default class BuildingLabel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let buildingNumber = this.props.buildingNumber;

      return (
        <div className={'building__label' + (this.props.isT ? ' t' : '')}>

          <p className="building__label-text">
            Budynek <span>{buildingNumber}</span>
          </p>
        </div>
      );
    }
}
