import React, {createRef, useRef, useEffect} from "react";
import s from "./EstateNew.scss";
import gsap from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

import background from "../../assets/images/estate-new.webp";
import backgroundA from "../../assets/images/estate-new.jpg";

const EstateNew = () => {
  const imageRef = useRef();
  const hRef = useRef();

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const firstImageTL = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: imageRef.current,
        scrub: 1,
        markers: false,
      },
    });

    firstImageTL.from(hRef.current, 0.4, {
      y: "-200",
    });
  });

  return (
    <section className="lp-estate-new">
      <div isLeft className="estate-new__bacground-wrapper" ref={imageRef}>
        <img
          className="estate-new__background"
          ref={hRef}
          alt=""
          src={backgroundA}
        />
      </div>
    </section>
  );
};

export default EstateNew;
