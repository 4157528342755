import React, { Component } from "react";
import ListItem from "./ListItem";
import ListMobileItem from "./ListMobileItem";

import { NavHashLink as NavLink } from "react-router-hash-link";
import s from "./ApartmentList.scss";
import CheckedFilter from "./CheckedFilter";
import StatusFilter from "./StatusFilter";

export default class ApartmentList extends React.Component {
  constructor(props) {
    super(props);
    this.filterStyle = {
      zIndex: 20,
    };
    this.arrowStyle = {
      transform: "rotate(45deg)",
      WebkitTransform: "rotate(45deg)",
    };
    this.lastFalseCallback = false;
    this.form = {};
    this.state = {
      params: false,
      sort: false,
      asc: false,
      choosedFlat: false,
      contact: false,
      showedOptions: [false, false, false, false, false, false, false],
      isDisabledUp: [false, false, false, false, false, false],
      isDisabledDown: [false, false, false, false, false, false],

      paginationPage: 0,
      paginationPerPage: 20,

      freeStatus: false,
      reservationStatus: false,
      soldStatus: false,
      apartmentsToSend: [],

      validate: {
        inputEmail: true,
      },
      wasFormSent: false,

      windowWidth: 0,
    };
  }

  closeModal = () => {
    this.setState({
      choosedFlat: false,
    });
  };

  toggleContact = (e) => {
    e.preventDefault();
    this.setState({
      contact: !this.state.contact,
    });
  };

  pickFlat = (flat) => {
    this.setState({
      choosedFlat: { flat: flat },
    });
  };

  sortByText = (arr, attr, asc) => {
    function reverseArr(input) {
      var ret = new Array();
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    }

    let newArr = arr.slice(0);

    newArr.sort(function (a, b) {
      if (attr === "brutto") {
        if (a.flatStatus == 1 && b.flatStatus == 1) {
          if (typeof a[attr] == "string" && a[attr] !== "") {
            a[attr] = parseFloat(a[attr]);
          }
          if (typeof b[attr] == "string" && b[attr] !== "") {
            b[attr] = parseFloat(b[attr]);
          }
        }
      } else if (attr !== "rooms" && attr !== "storey") {
        if (typeof a[attr] == "string" && a[attr] !== "") {
          a[attr] = parseFloat(a[attr]);
        }
        if (typeof b[attr] == "string" && b[attr] !== "") {
          b[attr] = parseFloat(b[attr]);
        }
      }
      if (a[attr] < b[attr]) {
        return -1;
      }
      if (a[attr] > b[attr]) {
        return 1;
      }
      return 0;
    });

    if (asc) {
      newArr = reverseArr(newArr);
    }
    return newArr;
  };

  toggleSortUp = (attr, num, callback) => {
    if (this.state.sort == attr) {
      this.state.asc && this.lastFalseCallback && this.lastFalseCallback();

      this.setState({
        asc: false,
      });
    } else {
      this.setState({
        sort: attr,
        asc: false,
      });
    }
    this.lastFalseCallback = callback;
    this.handleDisabledDown(num);
  };

  toggleSortDown = (attr, num, callback) => {
    if (this.state.sort == attr) {
      !this.state.asc && this.lastFalseCallback && this.lastFalseCallback();

      this.setState({
        asc: true,
      });
    } else {
      this.setState({
        sort: attr,
        asc: true,
      });
    }

    this.lastFalseCallback = callback;
    this.handleDisabledUp(num);
  };

  toggleOptions = (num) => {
    this.setState((prevState) => {
      let newItems = [...prevState.showedOptions];
      let toggledState = !newItems[num];
      newItems = newItems.map(() => false);
      newItems[num] = toggledState;
      return { showedOptions: newItems };
    });
  };

  handleDisabledUp = (num) => {
    this.setState((prevState) => {
      let newItems = [...prevState.isDisabledUp];
      let toggledState = !newItems[num];
      newItems = newItems.map(() => false);
      newItems[num] = toggledState;
      return {
        isDisabledUp: newItems,
        isDisabledDown: prevState.isDisabledDown.map(() => false),
      };
    });
  };

  handleDisabledDown = (num) => {
    this.setState((prevState) => {
      let newItems = [...prevState.isDisabledDown];
      let toggledState = !newItems[num];
      newItems = newItems.map(() => false);
      newItems[num] = toggledState;
      return {
        isDisabledDown: newItems,
        isDisabledUp: prevState.isDisabledUp.map(() => false),
      };
    });
  };

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    window.addEventListener("click", (e) => {
      if (
        e.target.tagName != "BUTTON" &&
        e.target.parentNode.tagName != "BUTTON"
      ) {
        if (
          document.querySelector(".button__options") &&
          document.querySelector(".button__options").contains(e.target)
        ) {
        } else {
          this.setState({
            showedOptions: this.state.showedOptions.map(() => false),
          });
        }
      }
    });

    if (
      typeof this.props.searchParams != "undefined" &&
      Object.keys(this.props.searchParams).length > 0
    ) {
      this.setState({ params: this.props.searchParams });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      if (nextProps !== "undefined" && Object.keys(nextProps).length > 0) {
        this.setState({
          params: nextProps.searchParams,
        });
      }
    }
  };

  seeNextPage = () => {
    if (this.state.paginationPage < 9) {
      this.setState({ paginationPage: this.state.paginationPage + 1 });
    }
  };

  seePreviousPage = () => {
    if (this.state.paginationPage > 0) {
      this.setState({ paginationPage: this.state.paginationPage - 1 });
    }
  };

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  sendForm = (e) => {
    e.preventDefault();

    if (!this.state.wasFormSent) {
      var validate = {
        inputEmail: true,
      };

      var valid = true;

      for (var property in this.form) {
        if (this.form.hasOwnProperty(property)) {
          var element = this.form[property];

          if (element.type == "email") {
            this.validateEmail(element.value);
            var v = this.validateEmail(element.value);
            if (!v) valid = false;
            validate[property] = v;
          }
        }
      }

      this.setState({
        validate,
      });

      if (valid && this.state.apartmentsToSend) {
        let catalogData = {
          inputEmail: this.form.inputEmail.value,
          apartments: this.state.apartmentsToSend.map((a) => a.flatNumber),
        };

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/send-flats", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(catalogData));
        xhr.onload = () => {
          this.setState({
            wasFormSent: true,
          });

          for (var property in this.form) {
            if (this.form.hasOwnProperty(property)) {
              this.form[property].value = "";
            }
          }
        };
      }
    }
  };

  sendFlatByForm = (apartmentNum) => {
    this.setState((prevState) => ({
      apartmentsToSend: [...prevState.apartmentsToSend, apartmentNum],
    }));
  };

  clearApartmentsArray = (id) => {
    this.setState({
      apartmentsToSend: this.state.apartmentsToSend.filter(function (
        apartment
      ) {
        return apartment._id !== id;
      }),
    });
  };

  render() {
    let flatList = this.props.flats || [];

    // HIDE FLATS K4 and K5
    flatList = flatList.filter(
      (element) =>
        element.buildingNumber !== "K4" && element.buildingNumber !== "K5"
    );

    if (this.state.params) {
      let p = this.state.params;

      const parse = (c, max) =>
        c == "Poddasze"
          ? 2
          : c == "Parter"
          ? 0
          : !Number(c) && max
          ? 9999999999
          : String(c).match(/[0-9,.]+/g) == null
          ? 0
          : Number(
              String(
                String(c)
                  .match(/[0-9,.]+/g)
                  .join("")
              ).replace(",", ".")
            );

      flatList = flatList.filter(
        (flat) => parse(p.areaFrom) <= parse(flat.area)
      );
      flatList = flatList.filter(
        (flat) => parse(p.areaTo, true) >= parse(flat.area)
      );

      flatList = flatList.filter((flat) => {
        return p.floorFrom == -1 || parse(p.floorFrom) <= parse(flat.storey);
      });

      flatList = flatList.filter((flat) => {
        return p.floorTo == -1 || parse(p.floorTo) >= parse(flat.storey);
      });

      flatList = flatList.filter(
        (flat) => parse(p.priceFrom) <= parse(flat.brutto)
      );
      flatList = flatList.filter(
        (flat) => parse(p.priceTo, true) >= parse(flat.brutto)
      );

      flatList = flatList.filter(
        (flat) => parse(p.roomsFrom) <= parse(flat.rooms)
      );
      flatList = flatList.filter(
        (flat) => parse(p.roomsTo, true) >= parse(flat.rooms)
      );

      flatList = flatList.filter((flat) =>
        Number(p.garden) ? parse(flat.gardenArea) > 0 : true
      );
      flatList = flatList.filter((flat) =>
        Number(p.balcony) ? parse(flat.balconyArea) > 0 : true
      );

      flatList = flatList.filter((flat) =>
        Number(p.wardrobe) ? parse(flat.wardrobe) > 0 : true
      );

      flatList = flatList.filter((flat) =>
        Number(p.loggia) ? parse(flat.loggia) > 0 : true
      );

      flatList = flatList.filter((flat) =>
        Number(p.free) ? flat.flatStatus == 1 : true
      );
      flatList = flatList.filter((flat) =>
        Number(p.promotions) ? flat.promotions == 1 : true
      );
      // if(Number(p.east) {return flat.compassE == 1 } else {return true}) true zwraca all
      flatList = flatList.filter((flat) =>
        Number(p.east) ? Number(p.east) && flat.compassE : true
      );
      flatList = flatList.filter((flat) =>
        Number(p.south) ? Number(p.south) && flat.compassS : true
      );
      flatList = flatList.filter((flat) =>
        Number(p.north) ? Number(p.north) && flat.compassN : true
      );
      flatList = flatList.filter((flat) =>
        Number(p.west) ? Number(p.west) && flat.compassW : true
      );
    }

    flatList = flatList.filter((flat) =>
      this.state.freeStatus ? flat.flatStatus == 1 : true
    );
    flatList = flatList.filter((flat) =>
      this.state.reservationStatus ? flat.flatStatus == 2 : true
    );
    flatList = flatList.filter((flat) =>
      this.state.soldStatus ? flat.flatStatus == 3 : true
    );

    if (this.state.sort) {
      flatList = this.sortByText(flatList, this.state.sort, this.state.asc);
    }

    let buttonsQuantity = Math.ceil(
      flatList.length / this.state.paginationPerPage
    );

    if (this.state.paginationPage >= 0) {
      const paginationFrom =
        this.state.paginationPage * this.state.paginationPerPage;
      flatList = flatList.filter(
        (item, index) =>
          index >= paginationFrom &&
          index < paginationFrom + this.state.paginationPerPage
      );
    }

    return (
      <section className="lp-apartments-list">

        <p className="lp-apartments-list-info">Komórka lokatorska do każdego mieszkania w cenie</p>

        <div className="apartments-list__container container">
          {this.state.windowWidth <= 610 ? (
            <div className="apartments-list__mobile">
              {flatList.length ? (
                flatList.map((i) => (
                  <ListMobileItem
                    clearApartmentsArray={this.clearApartmentsArray}
                    num={i._id}
                    key={i._id}
                    sendFlatByForm={this.sendFlatByForm}
                    pickFlat={this.pickFlat}
                    flat={i}
                    chooseFlatToForm={this.props.chooseFlatToForm}
                  />
                ))
              ) : (
                <div className="no-results-container">
                  <h3 className="no-results-title">
                    Brak wyników spełniających kryteria.
                  </h3>
                  <NavLink
                    className="no-results-button button-basic"
                    to={"/lista-apartamentow"}
                  >
                    Powrót do listy
                  </NavLink>
                </div>
              )}
            </div>
          ) : (
            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={
                      this.state.showedOptions[0] ? this.filterStyle : null
                    }
                  >
                    <button>
                      <span>Mieszkanie</span>
                    </button>
                    {this.state.showedOptions[0] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[0]}
                          num={0}
                          item="flatNumber"
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[0]}
                          num={0}
                          item="flatNumber"
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[1] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(1);
                      }}
                    >
                      <span>Piętro</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[1] ? this.arrowStyle : null
                        }
                      />
                    </button>
                    {this.state.showedOptions[1] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[1]}
                          item="storey"
                          num={1}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[1]}
                          item="storey"
                          num={1}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[2] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(2);
                      }}
                    >
                      <span>Pokoje</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[2] ? this.arrowStyle : null
                        }
                      />
                    </button>
                    {this.state.showedOptions[2] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[2]}
                          item="rooms"
                          num={2}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[2]}
                          item="rooms"
                          num={2}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[3] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(3);
                      }}
                    >
                      <span>Powierzchnia</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[3] ? this.arrowStyle : null
                        }
                      />
                    </button>
                    {this.state.showedOptions[3] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[3]}
                          item="area"
                          num={3}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[3]}
                          item="area"
                          num={3}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[4] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(4);
                      }}
                    >
                      <span>Cena</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[4] ? this.arrowStyle : null
                        }
                      />
                    </button>

                    {this.state.showedOptions[4] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[4]}
                          item="brutto"
                          num={4}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[4]}
                          item="brutto"
                          num={4}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[5] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(5);
                      }}
                    >
                      <span>Balkon</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[5] ? this.arrowStyle : null
                        }
                      />
                    </button>

                    {this.state.showedOptions[5] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[5]}
                          item="balconyArea"
                          num={5}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[5]}
                          item="balconyArea"
                          num={5}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th
                    style={
                      this.state.showedOptions[6] ? this.filterStyle : null
                    }
                  >
                    <button
                      onClick={() => {
                        this.toggleOptions(6);
                      }}
                    >
                      <span>Ogród</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[6] ? this.arrowStyle : null
                        }
                      />
                    </button>
                    {this.state.showedOptions[6] ? (
                      <div className="button__options">
                        <CheckedFilter
                          text="Rosnąco"
                          isDisabled={this.state.isDisabledDown[6]}
                          item="gardenArea"
                          num={6}
                          toggleSort={this.toggleSortUp}
                        />
                        <CheckedFilter
                          text="Malejąco"
                          isDisabled={this.state.isDisabledUp[6]}
                          item="gardenArea"
                          num={6}
                          toggleSort={this.toggleSortDown}
                        />
                      </div>
                    ) : null}
                  </th>
                  <th>
                    <button
                      onClick={() => {
                        this.toggleOptions(7);
                      }}
                    >
                      <span>Status</span>
                      <i
                        className="control-button--up"
                        style={
                          this.state.showedOptions[7] ? this.arrowStyle : null
                        }
                      />
                    </button>

                    {this.state.showedOptions[7] ? (
                      <div className="button__options">
                        <StatusFilter
                          text="Wolne"
                          item="flatStatus"
                          isChecked={this.state.freeStatus}
                          num={7}
                          sort={() => {
                            this.setState({
                              freeStatus: !this.state.freeStatus,
                              reservationStatus: false,
                              soldStatus: false,
                            });
                          }}
                        />
                        <StatusFilter
                          text="Rezerwacja"
                          item="flatStatus"
                          isChecked={this.state.reservationStatus}
                          num={7}
                          sort={() => {
                            this.setState({
                              reservationStatus: !this.state.reservationStatus,
                              freeStatus: false,
                              soldStatus: false,
                            });
                          }}
                        />
                        <StatusFilter
                          text="Sprzedane"
                          item="flatStatus"
                          isChecked={this.state.soldStatus}
                          num={7}
                          sort={() => {
                            this.setState({
                              soldStatus: !this.state.soldStatus,
                              freeStatus: false,
                              reservationStatus: false,
                            });
                          }}
                        />
                      </div>
                    ) : null}
                  </th>

                  <th>
                    <button>
                      <span>Wyślij zapytanie</span>
                    </button>
                  </th>
                  <th>
                    <button>
                      <span>PDF</span>
                    </button>
                  </th>
                  <th>
                    <button>
                      <span>Szczegóły</span>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {flatList.length ? (
                  flatList.map((i) => (
                    <ListItem
                      clearApartmentsArray={this.clearApartmentsArray}
                      num={i._id}
                      key={i._id}
                      sendFlatByForm={this.sendFlatByForm}
                      pickFlat={this.pickFlat}
                      flat={i}
                      chooseFlatToForm={this.props.chooseFlatToForm}
                    />
                  ))
                ) : (
                  <div className="no-results-container">
                    <h3 className="no-results-title">
                      Brak wyników spełniających kryteria.
                    </h3>
                    <NavLink
                      className="no-results-button button-basic"
                      to={"/lista-apartamentow"}
                    >
                      Powrót do listy
                    </NavLink>
                  </div>
                )}
              </tbody>
            </table>
          )}

          {flatList.length ? (
            <div className="pagination" id="pagination">
              {this.state.paginationPage == 0 ? null : (
                <a
                  onClick={() => {
                    this.seePreviousPage();
                  }}
                >
                  &laquo;
                </a>
              )}

              {(() => {
                let paginationArray = [];

                const isInScope = (number, i) => {
                  return (
                    number == i ||
                    number == i - 1 ||
                    number == i + 1 ||
                    i == 1 ||
                    i == buttonsQuantity - 1
                  );
                };

                function goToPage(event) {
                  event.preventDefault();

                  this.t.setState({
                    paginationPage: this.i,
                  });
                }

                let f = true;
                for (let i = 0; i < buttonsQuantity; i++) {
                  if (isInScope(this.state.paginationPage, i)) {
                    paginationArray.push(
                      <a
                        key={"a" + i}
                        className={
                          this.state.paginationPage == i ? " active" : ""
                        }
                        onClick={goToPage.bind({ t: this, i })}
                        href="#"
                      >
                        {i + 1}
                      </a>
                    );
                    f = true;
                  } else if (f) {
                    paginationArray.push(<i>...</i>);
                    f = false;
                  }
                }

                return paginationArray;
              })()}

              {this.state.paginationPage == buttonsQuantity - 1 ? null : (
                <a
                  onClick={() => {
                    this.seeNextPage();
                  }}
                >
                  &raquo;
                </a>
              )}
            </div>
          ) : null}

          <div className="apartments-list__form-box">
            <h3 className="form-box__info text-basic">
              <span>Zaznacz wybrane mieszkania.</span> Wpisz swój email i wyślij
              do siebie lub znajomych.
            </h3>
            <form
              className="apartments-list__form"
              method="post"
              onSubmit={this.sendForm}
            >
              <input
                disabled={this.state.wasFormSent ? "disabled" : ""}
                className={this.state.validate.inputEmail ? "" : "error"}
                ref={(input) => {
                  this.form.inputEmail = input;
                }}
                type="email"
                placeholder={"Wpisz swój e-mail"}
              />
              <button className="apartments-list__button button-basic">
                Wyślij
              </button>
            </form>
            {this.state.wasFormSent ? (
              <p className="apartments-list__sent text-basic">
                Karty mieszkań zostały wysłane.
              </p>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
