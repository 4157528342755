import React, { useEffect, useState } from "react";
import s from "./ApartmentsNewSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import apartments1 from "../../assets/images/apartments-new1.webp";
import apartments1a from "../../assets/images/apartments-new1.jpg";

import Image from "../Image/Image";

function Item({ element }) {
  const [number, setNumber] = useState(element.title);
  const [numToShow, setNumToShow] = useState(0);

  useEffect(() => {
    const changeNumber = setInterval(() => {
      countNumbers(number);
    }, 70);

    return () => {
      clearInterval(changeNumber);
    };
  });

  const countNumbers = (number) => {
    if (numToShow < number) {
      setNumToShow(numToShow + 1);
    } else {
    }
  };

  return (
    <div className="apartments-new__box" key={"apartments-new" + element.id}>
      <h3 className="apartments-new__box-title">{numToShow}</h3>
      <p className="apartments-new__box-text">{element.text}</p>
    </div>
  );
}

export default class ApartmentsNewSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let apartmentsData = [
      { id: 0, title: 175, text: "mieszkań w pierwszym etapie" },
      { id: 1, title: 141, text: "mieszkań z widokiem na Odrę" },
      { id: 2, title: 33, text: "mieszkania o powierzchni do 40m2" },
      { id: 3, title: 15, text: "mieszkań z ogródkami" },
    ];

    return (
      <section className="lp-apartments-new">
        <div className="apartments-new__container container-new">
          <div className="apartments-new__top row">
            <div className="apartments-new__top-left col-8 col-m-12">
              <div className="apartments-new__left-top-left">
                <h2 className="apartments-new__title text-basic">Mieszkania</h2>
                <p className="apartments-new__text text-basic">
                  Lokalizacja stawia naszą inwestycję w kategorii premium. Długa 15 to dobry adres do życia i miejsce
                  inwestycji.
                </p>

                <Link
                  to="/apartamenty"
                  className="apartments-new__button button-basic button-black"
                >
                  Wybierz mieszkanie
                </Link>
              </div>

              <div className="apartments-new__left-top-right">
                {apartmentsData.map((element) => (
                  <Item element={element} />
                ))}
              </div>
            </div>

            <div className="apartments-new__top-right col-4 col-m-12">
              {/* <img
                className="apartments-new__background"
                alt=""
                srcSet={apartments1}
                src={apartments1a}
              /> */}
              <picture className="apartments-new__background">
                <source srcset={apartments1} type="image/webp" />
                <source srcset={apartments1a} type="image/jpeg" />
                <img
                  src={apartments1a}
                  className="apartments-new__background"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
