import store from "../../store/index";
import { connect } from "react-redux";

import ApartmentNewSlick from "./ApartmentNewSlick";

const mapStateToProps = state => {
  return { flats: state.flats, ssr: state.ssr };
};

export default connect(mapStateToProps)(ApartmentNewSlick);
