import React, {Component} from "react";
import ContactForm from "../ContactForm/";
import s from "./ContactSection.scss";

export default class ContactSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <section className="lp-contact" id="kontakt">
        <div className="contact__container container row">
          <div className="col-5 col-m-12 contact__info-box">
            <h4 className="contact__subtitle">Hossanova 2 Sp. z o.o.</h4>

            <p className="contact__text">
              <span>Siedziba we Wrocławiu</span>
              <span>ul Kwiatkowskiego 4</span>
              <span>52-407 Wrocław</span>
            </p>

            <p className="contact__text">
              <span>Kontakt:</span>

              <a href="tel:0048717261357">71 726 13 57</a>
              <a href="mailto:kontakt@hossanova.pl">kontakt@hossanova.pl</a>
            </p>

            <p className="contact__text">
              <span>KRS 0000789565</span>
              <span>NIP 383863690</span>
              <span>REGON 8943143289</span>
            </p>

            <p className="contact__info">
              {" "}
              * Prezentowane na stronie wizualizacje i rzuty mieszkań zostały
              załączone w celu przedstawienia osiedla i rozkładu funkcjonalnego
              mieszkań i należy je traktować poglądowo. Rozmieszczenie mebli i
              przyborów stanowi przykład aranżacji. Elementy mebli, wyposażenia
              ruchomego i wbudowanego nie stanowią oferty sprzedażowej. Dokładny
              opis wykończenia osiedla i roślinności na osiedlu dostępny w
              biurze sprzedaży.
            </p>
          </div>

          <div className="col-7 col-m-12 contact__form-box">
            <h2 className="contact__title title-basic">
              Chcesz tutaj zamieszkać?
              <br />
              Masz pytania?
            </h2>
            <p className="contact__subtitle text-basic">Napisz do nas.</p>
            <ContactForm
              params={this.props.params ? this.props.params : false}
            />
          </div>
        </div>
      </section>
    );
  }
}
