import React from "react";
import s from "./Benefits.scss";
import { Link } from "react-router-dom";

import safety from "../../assets/icons/benefits-safety.svg";
import nature from "../../assets/icons/benefits-nature.svg";
import infrastructure from "../../assets/icons/benefits-infrastructure.svg";
import kids from "../../assets/icons/benefits-kids.svg";
import comfort from "../../assets/icons/benefits-comfort.svg";
import key from "../../assets/icons/benefits-key.svg";
import park from "../../assets/icons/benefits-park.svg";
import center from "../../assets/icons/benefits-center.svg";

export default class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let benefits = [
      {
        title: "Bezpieczeństwo",
        text: "Możesz być pewien swojego bezpieczeństwa i bezpieczeństwa swoich dzieci, ponieważ kompleks będzie miał zamknięty obszar chroniony przez system nadzoru wideo. A dziedziniec dla pieszych w domu jest dodatkową gwarancją spokoju.",
        icon: safety,
      },
      {
        title: "Wygodne rozwiązania",
        text: "Budynki kompleksu spełniają współczesne wymagania mieszkaniowe i mają wszystko dla wygody swoich mieszkańców: reprezentacyjne lobby, wygodne rampy, schowki na rzeczy ponadgabarytowe i wózki dziecięce.",
        icon: comfort,
      },
      {
        title: "Wszystko dla dzieci",
        text: "Dla małych mieszkańców kompleksu na jego terenie znajduje się przedszkole z nowoczesnymi metodami edukacji i nauczania, a także kilka placów zabaw i boisk sportowych.",
        icon: kids,
      },
      {
        title: "Wykończenie pod klucz",
        text: "Dajemy kupującym możliwość zakupu mieszkania zarówno bez dekoracji, jak i z pełnowartościowym nowoczesnym wykończeniem pod klucz.",
        icon: key,
      },
      {
        title: "Twój komfort",
        text: "Dobrze zaprojektowana przestrzeń życiowa stwarza wyjątkowe warunki do relaksu. Kompleks zapewnia nowoczesną promenadę z kaskadową fontanną, architektura krajobrazu, przytulne miejsca do siedzenia, place zabaw i boiska sportowe.",
        icon: park,
      },
      {
        title: "Wygodne rozwiązania",
        text: "Od centrum Wrocławia do ul. Długiej jest 3 minuty spacerem. W okolicy jest duży wybór przedszkoli, szkół, supermarketów, sklepów. Niedaleko  znajdują się place, parki, tereny zielone i kilka centrów handlowych",
        icon: center,
      },
      {
        title: "Własna infrastruktura",
        text: "Wszystkie niezbędne usługi będą dostępne dla mieszkańców Długiej 15 bez opuszczania  kompleksu, ponieważ na parterze będą sklepy, salon kosmetyczny, sklepy, salon kosmetyczny, apteki, biura bankowe, gabinety dentystyczne, porady prawne i inne instytucje z zakresu usług.",
        icon: infrastructure,
      },
      {
        title: "Bliskość natury",
        text: " Kompleks jest oddalony od hałaśliwych i zanieczyszczonych autostrad, więc oddychanie tutaj jest zawsze łatwe i przyjemne. W pobliżu znajduje się park, a główną atrakcją miejsca jest Odra.",
        icon: nature,
      },
    ];

    return (
      <section className="lp-benefits">
        <div className="benefits__container container">
          <h2 className="benefits__title title-basic col-12">
            Zalety Dułgiej 15
          </h2>
          <div className="benefits__main row">
            {benefits.map((element, index) => (
              <div
                key={"benefit" + index}
                className="benefits__col col-6 col-m-6 col-s-12"
              >
                <div className="benefits__box">
                  <div className="benefits__icon">
                    <img src={element.icon} alt="benefits-icon" />
                  </div>

                  <div className="benefits__details">
                    <p className="benefits__details-text text-basic">
                      <span className="accent">{element.title}</span>
                      {/* <span className="text">{element.text}</span> */}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Link to="/apartamenty" className="benefits__button button-basic">
            Znajdź mieszkanie
          </Link>
        </div>
      </section>
    );
  }
}
