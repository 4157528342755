import React from "react";
import s from "./InvestmentSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import investment from "../../assets/images/investment-background.webp";
import investmentA from "../../assets/images/investment-background.jpg";

import investment2 from "../../assets/images/dluga15-exterior-04.webp";
import investment2a from "../../assets/images/dluga15-exterior-04.jpg";

import gsap from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";

export default class InvestmentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    gsap.registerPlugin(ScrollTrigger);
    // gsap.registerPlugin(CSSRulePlugin);

    this.imageBottomRight = React.createRef();
    this.imageInside = React.createRef();
  }

  componentDidMount = () => {
    // const rule = CSSRulePlugin.getRule(".investment__bottom-right:before")

    const firstImageTL = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: this.imageBottomRight.current,
        scrub: 1,
        markers: false,
      },
    });

    // firstImageTL.to( this.imageInside.current, { duration: 2.5, ease: " back.out(1.7)", z: -10 });

    firstImageTL.from(this.imageInside.current, { opacity: 0.9, scale: 1.4 });
    firstImageTL.to(this.imageInside.current, { opacity: 1, scale: 1.3 });

    // firstImageTL.from(this.imageInside.current, {
    //   scale:0.7, transformOrigin:'center',
    //   //  ease: "rough({strength: 5, points: 15, template: strong.inOut, taper: both, randomize: false})",
    //   duration:1,
    //   repeat:-1,
    //   repeatDelay:1
    // })
  };

  render() {
    return (
      <section className="lp-investment">
        <div className="investment__container container-new">
          <div className="investment__top row">
            <div className="investment__top-left col-8 col-m-12">
              <picture className="investment__background">
                <source srcset={investment} type="image/webp" />
                <source srcset={investmentA} type="image/jpeg" />
                <img src={investmentA} className="investment__background" />
              </picture>
            </div>

            <div className="investment__top-right col-4 col-m-12">
              <h2 className="investment__title text-basic">Twoja okolica</h2>
              <p className="investment__text text-basic">
                Bezpośrednia okolica Kępy Mieszczańska, Wyspa Słodowa, Rynek,
                Ostrów Tumski. Bliskość parku zachodniego i całej infrastruktury
                rekreacyjnej zlokalizowanej nad Odrą.
              </p>

              <Link
                to="/lista-apartamentow"
                className="investment__button button-basic button-black"
              >
                Wybierz mieszkanie
              </Link>
            </div>
          </div>

          <div className="investment__bottom row">
            <div className="investment__bottom-left col-4 col-m-12">
              <div className="investment__left-bottom-left">
                <h2 className="investment__title text-basic">
                  Szukasz inwestycji z potencjałem?
                </h2>

                <p className="investment__text text-basic">
                  Przy Długiej 15 zaktualizowaliśmy atrakcyjne powierzchnie
                  handlowe. W najwyższej wieży naszego osiedla znajdzie się
                  miejsca dla Recepcji.
                </p>

                <Link
                  to="/lista-apartamentow"
                  className="investment__button button-basic button-white"
                >
                  Lista mieszkań
                </Link>
              </div>
            </div>

            <div
              className="investment__bottom-right col-8 col-m-12"
              ref={this.imageBottomRight}
            >
              <img
                ref={this.imageInside}
                className="investment__background"
                alt=""
                src={investment2a}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
