import React, { Component } from "react";
import "./assets/scss/style.scss";

import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer/";
import VisualizationPage from "./components/VisualizationPage/";
import InvestmentPage from "./components/InvestmentPage/";
import LocalizationPage from "./components/LocalizationPage/";
import Gallery from "./components/Gallery/";
import GalleryConstruction from "./components/GalleryConstruction/";
import ApartmentsPage from "./components/ApartmentsPage/";
import ApartmentsListPage from "./components/ApartmentsListPage/";
import PrivacyPage from "./components/PrivacyPage/";
import Meeting from "./components/Meeting/";

import store from "./store/index";
import { addLastUrl } from "./actions/index";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };

    window.redirectTo = this.redirectTo;
  }

  redirectTo = (url) => {
    store.dispatch(addLastUrl(window.location.pathname));
    this.setState({
      redirect: url,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Header login={this.props.login} />

        {this.state.redirect
          ? (() => {
              let goToUrl = this.state.redirect;
              this.state.redirect = false;
              return <Redirect to={goToUrl} />;
            })()
          : ""}

        <Switch>
          <Route
            exact
            path="/"
            component={(props) => (
              <Home {...props} redirectTo={this.redirectTo} />
            )}
          />
          <Route path="/wyszukiwarka" component={() => <VisualizationPage />} />
          <Route path="/inwestycja" component={() => <InvestmentPage />} />
          <Route path="/lokalizacja" component={() => <LocalizationPage />} />
          <Route path="/galeria" component={() => <Gallery />} />
          <Route
            path="/dziennik-budowy"
            component={() => <GalleryConstruction />}
          />

          <Route
            path="/apartamenty/:buildingNumber/:storey/:flatNumber"
            component={(props) => (
              <ApartmentsPage {...props} redirectTo={this.redirectTo} />
            )}
          />
          <Route
            path="/apartamenty/:buildingNumber/:storey"
            component={(props) => (
              <ApartmentsPage {...props} redirectTo={this.redirectTo} />
            )}
          />
          <Route
            path="/apartamenty"
            component={(props) => (
              <ApartmentsPage {...props} redirectTo={this.redirectTo} />
            )}
          />
          <Route
            path="/lista-apartamentow"
            component={(props) => (
              <ApartmentsListPage {...props} redirectTo={this.redirectTo} />
            )}
          />
          <Route
            path="/znajdz-apartamenty/:areaFrom,:areaTo,:floorFrom,:floorTo,:priceFrom,:priceTo,:roomsFrom,:roomsTo,:east,:west,:north,:south,:garden,:balcony,:wardrobe,:loggia,:free,:promotions"
            component={(props) => (
              <ApartmentsListPage {...props} redirectTo={this.redirectTo} />
            )}
          />

          <Route path="/spotkanie" component={() => <Meeting />} />

          <Route
            path="/polityka-prywatnosci"
            component={() => <PrivacyPage />}
          />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { login: state.login };
};

export default connect(mapStateToProps)(App);
