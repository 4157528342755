import store from "../../store/index";
import { connect } from "react-redux";

import Privacy from "./Privacy";

const mapStateToProps = state => {
  return { text: state.text, flats:state.flats};
};

export default connect(mapStateToProps)(Privacy);
