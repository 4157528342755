import React from "react";
import "./Area.scss";
import icon from "../../assets/icons/accent.svg";

export default class Area extends React.Component {
  render() {
    return (
      <section className="lp-area" id='area'>
        <div className="area__container container">
          <h2 className="area__title title-basic">
            Jakość, design, funkcjonalność, lokalizacja
          </h2>
          <img
            src={icon}
            alt="icon"
            className="af-icon-accent area__icon"
          ></img>

          <div className="area__main">
            <p className="area__text text-basic">
              Filary Wszystkich naszych inwestycji.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
