import React from "react";
import s from "./HomeBanner.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import mainbackground from "../../assets/images/dluga15-S.jpg";

import BannerSideContact from "../BannerSideContact/";
import { Link } from "react-router-dom";

export default class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };
  render() {
    let bannerStyle = {
      background: `rgba(0,0,0, 1)`,
      background: `url("${mainbackground}")`,
      background: `-moz-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${mainbackground}") `,
      background: `-webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(0,0,0, 0.35)),
        color-stop(36%, rgba(0, 0, 0, 0.35)),
        color-stop(65%, rgba(0, 0, 0, 0.3)),
        color-stop(100%, rgba(0,0,0, 0.1))
      ),
      url("${mainbackground}") `,
      background: `-webkit-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${mainbackground}") `,
      background: `-o-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0,0.1) 100%
      ),
      url("${mainbackground}") `,
      background: `-ms-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${mainbackground}") `,
      background: `linear-gradient(
        to bottom,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${mainbackground}") `,
      backgroundSize: `cover`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `center`,
    };

    return (
      <section className="lp-home-banner" style={bannerStyle}>
        <BannerSideContact />

        <div className="home-banner__container container-new row">
          <div className="home-banner__left col-6 col-m-12">
            <h2 className="home-banner__title text-basic">
              Mieszkania <br /> nad Odrą <br/> <span>Sprzedaż zakończona. Dziękujemy!</span>
            </h2>
            {/* <p className="home-banner__text text-basic">Znajdź apartament</p> */}

            {/* <Link
              to="/lista-apartamentow"
              className="home-banner__button button-basic button-white"
            >
              <span className="af-icon-tasks-solid home-banner__button-icon"></span>

              <span className="home-banner__button-text">Według kryteriów</span>
            </Link> */}

{/*            <Link
              to="/apartamenty"
              className="home-banner__button button-basic button-white-other"
            >
              <span className="af-icon-building home-banner__button-icon"></span>
              <span className="home-banner__button-text">
                Znajdź mieszkanie
              </span>
            </Link>
            <Link
              to="/wyszukiwarka"
              className="home-banner__button button-basic button-white-other"
            >
              <span className="af-icon-3d home-banner__button-icon"></span>
              <span className="home-banner__button-text">Wyszukiwarka 3D</span>
            </Link>*/}
          </div>

          <div className="home-banner__media col-12">
            <NavLink
              className="contact__icon-box"
              smooth
              scroll={(el) => {
                this.scrollWithOffset(el);
              }}
              onClick={(e) => {
                if (window.location.pathname == "/") {
                  e.preventDefault();
                }
              }}
              to={"/#dluga15"}
            >
              Przewiń
              <span className="af-icon-arrow-down contact__icon"></span>
            </NavLink>
          </div>
        </div>
      </section>
    );
  }
}
