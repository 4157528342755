import React from "react";
import "./SpaceSection.scss";
import { Link } from "react-router-dom";
import space from "../../assets/images/space-new-background.webp";
import spaceA from "../../assets/images/space-new-background.jpg";

import space1 from "../../assets/images/K2-P4-1.webp";
import space1a from "../../assets/images/K2-P4-1.jpg";

import space2 from "../../assets/images/space2.webp";
import space2a from "../../assets/images/space2.jpg";

import space3 from "../../assets/images/space3.webp";
import space3a from "../../assets/images/space3.jpg";

export default class SpaceSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="lp-space">
        <div className="space__container container-new">
          <div className="space__top row">
            <div className="space__top-left col-4 col-m-12">
              <picture className="space__background">
                <source srcset={space} type="image/webp" />
                <source srcset={spaceA} type="image/jpeg" />
                <img src={spaceA} className="space__background" alt="" />
              </picture>
            </div>

            <div className="space__top-right col-8 col-m-12">
              <div className="space__right-top-left">
                <h2 className="space__title text-basic">Twoja przestrzeń</h2>
                <p className="space__text text-basic">
                  Mieszkania od 30 do 85m2. Ustawne i przemyślane mieszkania z
                  widokiem na Odrę.
                </p>

                <Link
                  to="/apartamenty"
                  className="space__button button-basic button-black"
                >
                  Wybierz mieszkanie
                </Link>
              </div>

              <div className="space__right-top-right">
                <picture className="space__background">
                  <source srcset={space1} type="image/webp" />
                  <source srcset={space1a} type="image/jpeg" />
                  <img src={space1a} className="space__background" alt="" />
                </picture>
              </div>
            </div>
          </div>

          <div className="space__bottom row">
            <div className="space__bottom-left col-8 col-m-12">
              <picture className="space__background">
                <source srcset={space2} type="image/webp" />
                <source srcset={space2a} type="image/jpeg" />
                <img src={space2a} className="space__background" alt="" />
              </picture>
            </div>

            <div className="space__bottom-right col-4 col-m-12">
              <picture className="space__background">
                <source srcset={space3} type="image/webp" />
                <source srcset={space3a} type="image/jpeg" />
                <img src={space3a} className="space__background" alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
