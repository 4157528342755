import store from "../../store/index";
import { connect } from "react-redux";

import BuildingLabel from "./BuildingLabel";

const mapStateToProps = state => {
  return { flats: state.flats };
};

export default connect(mapStateToProps)(BuildingLabel);
