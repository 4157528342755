import React, {Component, useState} from "react";
import GoogleMapReact from "google-map-react";
import s from "./GoogleMap.scss";
import logo from "../../assets/images/logo-dluga-black.svg";

function createMapOptions(maps) {
  return {
    disableDefaultUI: false,
    zoomControl: true,
    scaleControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    styles: [
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#999999",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            color: "#808080",
          },
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#b3b3b3",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
          {
            weight: 1.8,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#d7d7d7",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ebebeb",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#a7a7a7",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#696969",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#737373",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#d6d6d6",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {},
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
    ],
  };
}

function getZoom(height = false) {
  let r = height
    ? height - 15 >= 2
      ? Math.pow(height - 15, 2)
      : height - 15 >= 1
      ? 2
      : height - 15 >= 0
      ? 1
      : Math.pow(Math.abs(height - 15 - 1), -1)
    : 12 - (2 - 2 * (window.innerHeight / 1080).toFixed(3));
  return r;
}

function MapPin({m}) {
  const [name, setName] = useState(false);

  const toggleName = () => {
    setName(!name);
  };

  return (
    <div className="map-marker-circle" onClick={toggleName}>
      <span className={"af-icon-" + m.svgIcon} />

      {name ? <p className="map-pin-name text-basic">{m.name}</p> : null}
    </div>
  );
}

class GMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapScaleZoom: false,
    };
  }

  handleApiLoaded(map, maps) {
    maps.event.addListener(map, "zoom_changed", () => {
      let zoomLevel = map.getZoom();
      this.setState({
        mapScaleZoom: zoomLevel,
      });
    });

    let zoomLevel = map.getZoom();
    this.setState({
      mapScaleZoom: zoomLevel,
    });
  }

  render() {
    let mapLocations = [
      {
        name: "Euronet",
        investment: true,
        svgIcon: "bank",
        lat: 51.12011,
        lng: 17.016098,
      },
      {
        name: "PKO Bank Polski",
        investment: true,
        svgIcon: "bank",
        lat: 51.121409,
        lng: 17.015433,
      },
      {
        name: "Multibank",
        investment: true,
        svgIcon: "bank",
        lat: 51.121183,
        lng: 17.015804,
      },
      {
        name: "Credit Agricole",
        investment: true,
        svgIcon: "bank",
        lat: 51.114559086146784,
        lng: 17.005337592021338,
      },
      {
        name: "ING Bank",
        investment: true,
        svgIcon: "bank",
        lat: 51.11311385903845,
        lng: 17.019521754216793,
      },
      {
        name: "Euronet",
        investment: true,
        svgIcon: "bank",
        lat: 51.1131837904247,
        lng: 17.007973915352874,
      },
      {
        name: "MBank",
        investment: true,
        svgIcon: "bank",
        lat: 51.11162553024921,
        lng: 17.023240405230922,
      },

      {
        name: "Rabat",
        investment: true,
        svgIcon: "shop",
        lat: 51.118004,
        lng: 17.01046,
      },
      {
        name: "Żabka",
        investment: true,
        svgIcon: "shop",
        lat: 51.120351,
        lng: 17.011319,
      },
      {
        name: "Żabka",
        investment: true,
        svgIcon: "shop",
        lat: 51.117239,
        lng: 17.015782,
      },
      {
        name: "Lidl",
        investment: true,
        svgIcon: "shop",
        lat: 51.112289,
        lng: 17.00655,
      },
      {
        name: "Biedronka",
        investment: true,
        svgIcon: "shop",
        lat: 51.125648,
        lng: 16.987152,
      },
      {
        name: "Biedronka",
        investment: true,
        svgIcon: "shop",
        lat: 51.118267,
        lng: 17.032814,
      },
      {
        name: "Delikatesy Centrum",
        investment: true,
        svgIcon: "shop",
        lat: 51.11657536928188,
        lng: 17.00778937499017,
      },

      {
        name: "Szkoła podstawowa nr 46",
        svgIcon: "education",
        investment: true,
        lat: 51.119085,
        lng: 17.010101,
      },
      {
        name: "Zespół Szkół nr 18",
        svgIcon: "education",
        investment: true,
        lat: 51.11914,
        lng: 17.012952,
      },
      {
        name: "Zespół Szkolno-Przedszkolny nr 18",
        svgIcon: "education",
        investment: true,
        lat: 51.117362,
        lng: 17.006429,
      },
      {
        name: "Mały Twórca Przedszkole Niepubliczne",
        svgIcon: "education",
        investment: true,
        lat: 51.119753,
        lng: 17.002453,
      },
      {
        name: "Przedszkole nr 99",
        svgIcon: "education",
        investment: true,
        lat: 51.114365,
        lng: 17.014426,
      },
      {
        name: "Gimnazjum Mistrzostwa Sportowego",
        svgIcon: "education",
        investment: true,
        lat: 51.11580367775638,
        lng: 17.013387714225306,
      },

      {
        name: "Szkoła Podstawowa nr 14",
        svgIcon: "education",
        investment: true,
        lat: 51.11550237367436,
        lng: 17.01538760723525,
      },

      {
        name: "Żłobek Jasia i Asi",
        svgIcon: "nursery",
        investment: true,
        lat: 51.113829,
        lng: 17.007989,
      },
      {
        name: "Żłobek Elfiki",
        svgIcon: "nursery",
        investment: true,
        lat: 51.11593,
        lng: 17.000951,
      },
      {
        name: "Żłobek nr 3",
        svgIcon: "nursery",
        investment: true,
        lat: 51.124631,
        lng: 16.993913,
      },
      {
        name: "Żłobek Kredka",
        svgIcon: "nursery",
        investment: true,
        lat: 51.12319606313324,
        lng: 16.99332987444154,
      },
      {
        name: "Żłobek nr 10",
        svgIcon: "nursery",
        investment: true,
        lat: 51.12000650262128,
        lng: 17.034585507036173,
      },

      {
        name: "Żłobek i przedszkole FAIR PLAY",
        svgIcon: "nursery",
        investment: true,
        lat: 51.11335637173907,
        lng: 16.994191161134538,
      },

      {
        name: "Żłobek nr 8",
        svgIcon: "nursery",
        investment: true,
        lat: 51.1073001352913,
        lng: 17.02304426542428,
      },

      {
        name: "Park",
        svgIcon: "recreation",
        investment: true,
        lat: 51.116475,
        lng: 17.014022,
      },
      {
        name: "Siłownia na powietrzu",
        svgIcon: "recreation",
        investment: true,
        lat: 51.117012,
        lng: 17.014955,
      },
      {
        name: "Joga na wyspie",
        svgIcon: "recreation",
        investment: true,
        lat: 51.119058761105784,
        lng: 17.021462446672636,
      },

      {
        name: "Centrum treningowe Spartan",
        svgIcon: "recreation",
        investment: true,
        lat: 51.119866898136486,
        lng: 17.006356246427426,
      },

      {
        name: "MCFIT",
        svgIcon: "recreation",
        investment: true,
        lat: 51.12228861032159,
        lng: 16.99133586985219,
      },
      {
        name: "City Fit",
        svgIcon: "recreation",
        investment: true,
        lat: 51.108014686212066,
        lng: 17.033883431191217,
      },
      {
        name: "Boisko",
        svgIcon: "recreation",
        investment: true,
        lat: 51.1055894524269,
        lng: 17.019848060734088,
      },

      {
        name: "Plac zabaw Naturalne Drzewo",
        svgIcon: "playground",
        investment: true,
        lat: 51.116637,
        lng: 17.01336,
      },
      {
        name: "Plac zabaw",
        svgIcon: "playground",
        investment: true,
        lat: 51.117961,
        lng: 17.007885,
      },
      {
        name: "Plac zabaw Wyspa przygód",
        svgIcon: "playground",
        investment: true,
        lat: 51.116965,
        lng: 17.005418,
      },
      {
        name: "Plac zabaw",
        svgIcon: "playground",
        investment: true,
        lat: 51.11707022017089,
        lng: 17.019279639308927,
      },
      {
        name: "Plac zabaw",
        svgIcon: "playground",
        investment: true,
        lat: 51.13053790614047,
        lng: 16.99807946025912,
      },

      {
        name: "Plac zabaw ",
        svgIcon: "playground",
        investment: true,
        lat: 51.117996629391584,
        lng: 17.038215418931156,
      },

      {
        name: "Plac zabaw",
        svgIcon: "playground",
        investment: true,
        lat: 51.11291428069189,
        lng: 17.014530035594653,
      },

      {
        name: "Kościół pw Chrystusa Króla",
        svgIcon: "church",
        investment: true,
        lat: 51.115704,
        lng: 17.01325,
      },
      {
        name: "Parafia pw św. Elżbiety Węgierskie",
        svgIcon: "church",
        investment: true,
        lat: 51.111739,
        lng: 17.030491,
      },
      {
        name: "Kościół pw św. Antoniego z Padwy",
        svgIcon: "church",
        investment: true,
        lat: 51.11081388707252,
        lng: 17.024541228958622,
      },

      {
        name: "Kościół pw św. Jerzego",
        svgIcon: "church",
        investment: true,
        lat: 51.12803854523908,
        lng: 16.98888718943609,
      },
      {
        name: "Sanktuarium Matki Bożej Miłosierdzia",
        svgIcon: "church",
        investment: true,
        lat: 51.12294074015736,
        lng: 17.041219170464057,
      },
      {
        name: "Kościół Rzymskokatolicki pw Najświętszego Imienia Jezus",
        svgIcon: "church",
        investment: true,
        lat: 51.11512455743046,
        lng: 17.034325927079152,
      },
      {
        name: "Kościół pw św. Stanisława, św. Doroty i św. Wacława",
        svgIcon: "church",
        investment: true,
        lat: 51.10774228899752,
        lng: 17.0308926999709,
      },

      {
        name: "Euromedicare",
        svgIcon: "health-service",
        investment: true,
        lat: 51.12681261230218,
        lng: 17.0270303127178,
      },
      {
        name: "Lekarz rodzinny Jagoda Ukleja",
        svgIcon: "health-service",
        investment: true,
        lat: 51.11821832580788,
        lng: 17.02842557219054,
      },

      {
        name: "MEDEX",
        svgIcon: "health-service",
        investment: true,
        lat: 51.11207591009647,
        lng: 17.02456319139199,
      },
      {
        name: "Lekarz Agnieszka Kus",
        svgIcon: "health-service",
        investment: true,
        lat: 51.11972686441273,
        lng: 17.020443318540206,
      },

      {
        name: "Lekarz rodzinny Domanscy",
        svgIcon: "health-service",
        investment: true,
        lat: 51.10954327291233,
        lng: 17.029112217877195,
      },

      {
        name: "Lekarz Góral Jerzy",
        svgIcon: "health-service",
        investment: true,
        lat: 51.114756640838316,
        lng: 17.012614606163947,
      },
      {
        name: "Lekarz Wiórek medycyna estetyczna",
        svgIcon: "health-service",
        investment: true,
        lat: 51.1156980170611,
        lng: 17.00305826501465,
      },

      {
        name: "Salon Payot",
        svgIcon: "beauty",
        investment: true,
        lat: 51.117429,
        lng: 17.01657,
      },
      {
        name: "Stylizacja Paznokci Nail Queen",
        svgIcon: "beauty",
        investment: true,
        lat: 51.114466,
        lng: 17.014984,
      },
      {
        name: "Salon Vanilla",
        svgIcon: "beauty",
        investment: true,
        lat: 51.119183986221366,
        lng: 17.013212089247318,
      },

      {
        name: "Kosmetyka Naturalna Ślusarczyk",
        svgIcon: "beauty",
        investment: true,
        lat: 51.11689419385988,
        lng: 17.016366366899465,
      },
      {
        name: "Salon Kleopatra",
        svgIcon: "beauty",
        investment: true,
        lat: 51.11994498033424,
        lng: 17.011484746676263,
      },
      {
        name: "Salon Lenda",
        svgIcon: "beauty",
        investment: true,
        lat: 51.120739633360714,
        lng: 17.012836579955756,
      },
      {
        name: "Beauty Skin",
        svgIcon: "beauty",
        investment: true,
        lat: 51.114903633440115,
        lng: 17.014360553564497,
      },

      {
        name: "Apteka",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.121835,
        lng: 17.016618,
      },
      {
        name: "DOZ dbam o zdrowie",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.11800508350824,
        lng: 17.000573493164463,
      },
      {
        name: "Vitrum",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.116819763149834,
        lng: 17.002504683563735,
      },
      {
        name: "Hetmanska",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.116334850602286,
        lng: 17.008040762708323,
      },
      {
        name: "Ginsana",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.11442209037109,
        lng: 17.009371138316713,
      },

      {
        name: "MAK",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.12282686875393,
        lng: 17.026666021225765,
      },
      {
        name: "Pod Lwami",
        svgIcon: "pharmacy",
        investment: true,
        lat: 51.11159321531488,
        lng: 17.023490285902515,
      },

      {
        name: "Odra'zuu - Restauracja",
        svgIcon: "pub",
        investment: true,
        lat: 51.115018,
        lng: 17.021597,
      },
      {
        name: "Osiem Misek",
        svgIcon: "pub",
        investment: true,
        lat: 51.109757,
        lng: 17.023324,
      },
      {
        name: "Pasibus",
        svgIcon: "pub",
        investment: true,
        lat: 51.110865,
        lng: 17.023404,
      },
      {
        name: "Pod papugami",
        svgIcon: "pub",
        investment: true,
        lat: 51.110384,
        lng: 17.031178,
      },
      {
        name: "Suszarnia",
        svgIcon: "pub",
        investment: true,
        lat: 51.116522774630845,
        lng: 17.007740353658914,
      },
      {
        name: "Manhattan",
        svgIcon: "pub",
        investment: true,
        lat: 51.1137209694379,
        lng: 17.018726681347005,
      },
      {
        name: "Warsztat",
        svgIcon: "pub",
        investment: true,
        lat: 51.12236416712897,
        lng: 16.994851558346635,
      },

      {
        name: "Kino Nowe Horyzonty",
        svgIcon: "culture",
        investment: true,
        lat: 51.110237,
        lng: 17.026145,
      },
      {
        name: "Narodowe Forum Muzyki",
        svgIcon: "culture",
        investment: true,
        lat: 51.107233,
        lng: 17.027325,
      },
      {
        name: "Teatr muzyczny Capitol",
        svgIcon: "culture",
        investment: true,
        lat: 51.10092,
        lng: 17.029994,
      },
      {
        name: "Galeria Kino 5d",
        svgIcon: "culture",
        investment: true,
        lat: 51.119671009668096,
        lng: 16.989031640182844,
      },

      {
        name: "DCF Kino",
        svgIcon: "culture",
        investment: true,
        lat: 51.10237371646673,
        lng: 17.029801215437146,
      },

      {
        name: "Wrocławski Teatr Współczesny im. Edmunda Wiercińskiego",
        svgIcon: "culture",
        investment: true,
        lat: 51.113256757909596,
        lng: 17.028771241200683,
      },

      {
        name: "Capitol",
        svgIcon: "culture",
        investment: true,
        lat: 51.10182691030126,
        lng: 17.02954370425949,
      },
    ];
    let mapPins = this.props.mapPins;

    mapPins.map(pin => {
      mapLocations = mapLocations.map(element => {
        if (element && pin.icon == element.svgIcon) {
          return {...element, active: pin.active};
        } else {
          return element;
        }
      });
    });

    mapLocations = mapLocations.filter(element =>
      element.active ? element : null
    );

    return (
      <div style={{height: "100%", width: "100%"}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: "AIzaSyAW3SzC84jT085qjdADy2Us-ZZJKBzMk7Q"}}
          defaultCenter={this.props.startCoords.center}
          defaultZoom={this.props.startCoords.zoom}
          options={createMapOptions}
          center={this.props.coords.center}
          zoom={this.props.coords.zoom}
          onGoogleApiLoaded={({map, maps}) =>
            this.handleApiLoaded.bind(this)(map, maps)
          }
        >
          {mapLocations.length > 0
            ? mapLocations.map((m, i) => (
                <div
                  key={"gm" + i}
                  className={
                    "map-marker" +
                    (typeof m.investment != "undefined" ? " inv" : "") +
                    (typeof m.smallIcon != "undefined" ? " small-icon" : "")
                  }
                  title={m.name}
                  lat={m.lat}
                  lng={m.lng}
                  style={{
                    transform:
                      "scale(" +
                      (getZoom(this.state.mapScaleZoom || 12) > 1
                        ? 1
                        : getZoom(this.state.mapScaleZoom || 12)) +
                      ")",
                  }}
                >
               
                  <MapPin m={m} />
                </div>
              ))
            : null}
          <span className="main-pin" lat={51.118787} lng={17.015905}>
            <img src={logo} alt="logo" />
          </span>
        </GoogleMapReact>
      </div>
    );
  }
}

export default class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    let baseCoords = {lat: 51.115201, lng: 17.027234};
    if ("innerWidth" in window && window.innerWidth != "undefined") {
      if (
        parseInt(window.innerWidth) > 0 &&
        parseInt(window.innerWidth) < 1200
      ) {
        baseCoords = {lat: 51.115201, lng: 17.027234};
      }
    }

    this.state = {
      startCoords: {
        center: baseCoords,
        zoom: 15, //getZoom(15)
      },
      coords: {
        center: baseCoords,
        zoom: 15, //getZoom(15)
      },
      shownId: false,
    };
  }

  zoomMap() {
    return {
      center:
        window.innerWidth < 1400
          ? {lat: 51.118787, lng: 17.015905}
          : {lat: 51.118787, lng: 17.015905},
      zoom: 15,
    };
  }

  setShownId(shownId, coords = false) {
    this.setState({
      shownId,
    });
    if (coords) {
      this.setState({
        coords: {
          center: {lat: Number(coords.lat) + 3.3, lng: Number(coords.lng)},
          zoom: 6,
        },
      });
    }
  }

  render() {
    let scale = 1;
    return (
      <section
        className="google-map"
        style={{
          transform: "scale(" + scale + ")",
        }}
      >
        <GMap
          setShownId={this.setShownId.bind(this)}
          shownId={this.state.shownId}
          coords={this.props.zoom ? this.zoomMap() : this.state.coords}
          startCoords={this.state.startCoords}
          mapPins={this.props.mapPins}
        />
      </section>
    );
  }
}
