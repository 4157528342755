import React from "react";

import "./LocalizationPage.scss";
import MainBanner from "../MainBanner/";
import About from "../About/";
import background from "../../assets/images/dluga15-exterior-tram.jpg";
import MapSection from "../MapSection/";
import History from "../History/";
import LocalizationView from "../LocalizationView/";
import LocalizationArea from "../LocalizationArea/";
import LocalizationInfo from "../LocalizationInfo/";

export default class LocalizationPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className="lp-localization-page">
        <MainBanner background={background} 
        scrollItem='#about'
        />
        <About
          title="W okolicy Długiej 15"
          text='Ulica Długa od wieków była jedną z ważniejszych dróg dojazdowych do Centrum Wrocławia. Obecnie przebudowywana i modernizowana zyskuje nowe możliwości rozwoju niezmiennie jednak oferuje dobrą infrastrukturę, wygodną komunikację z wszystkimi regionami miasta, bliskość terenów rekreacyjnych.'
        />
        <MapSection />
        <History />
        <LocalizationView />
        <LocalizationArea />
        <LocalizationInfo />
      </div>
    );
  }
}
