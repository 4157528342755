import React from "react";

import "./VisualizationPage.scss";
import ContactSection from "../ContactSection";

const initOdyssey = (trys = 0) => {
  try {
    window.Odyssey.init("#homerweb", {
      id: "105",
      theme: "light",
      src: "https://cdn.odysseycrew.com/homerweb/app/",
      height: "760px",
    });
  } catch (e) {
    setTimeout(() => {
      trys < 10 && initOdyssey(++trys);
    }, 1000 * 2);
  }
};

export default class VisualizationPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (document.readyState === "complete") {
      initOdyssey();
    } else {
      window.onload = initOdyssey();
    }
  };

  render() {
    return (
      <div className="lp-visualization-page">
        <iframe
          id="homerweb"
          frameborder="0"
          scrolling="no"
          width="100%"
          style={{ height: "760px" }}
          allowfullscreen
        />
        <ContactSection />
      </div>
    );
  }
}
