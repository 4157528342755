import React, {createRef, useRef, useEffect} from "react";
import s from "./RadiusSection.scss";

import dlugabackground from "../../assets/images/warstwy/dluga15-exterior-ujecie2-niebo.jpg";
import imageLight from "../../assets/images/warstwy/dluga15-exterior-ujecie2-jasno-small.png";
import image from "../../assets/images/warstwy/dluga15-exterior-ujecie2-ciemno-small.png";

import gsap from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

class RadiusSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.imageRef = React.createRef();
    this.hRef = React.createRef();
    this.imageInside = React.createRef();

    gsap.registerPlugin(ScrollTrigger);
  }

  componentDidMount = () => {
    const firstImageTL = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: this.imageRef.current,
        scrub: 1,
        markers: false,
      },
    });
    firstImageTL.from(this.hRef.current, 0.4, {
      x: "-400",
      y: "-400",
    });
  };

  moveMouse = e => {
    // this.imageRef.current.style.backgroundPositionX =
    //   -e.nativeEvent.offsetX / 6 + "px";
    // this.imageRef.current.style.backgroundPositionY =
    //   -e.nativeEvent.offsetY / 6 + "px";

    let rotateStyle = `rotateX(${
      (e.nativeEvent.offsetX / window.innerWidth) * 5
    }deg) rotateY(${
      (-e.nativeEvent.offsetY / window.innerHeight) * 5
    }deg) rotateY(0deg) translate(0px, 0px)`;

    this.imageInside.current.style.transform = rotateStyle;
    this.imageInside.current.style.webkitTransform = rotateStyle;
    this.imageInside.current.style.MozTransform = rotateStyle;
  };

  enterMouse = () => {
    this.setState({lightOn: true})
  }
  leaveMouse = () => {
    this.setState({lightOn: false})
  }

  render() {
    return (
      <section className="lp-radius">
        <div isLeft className="radius__background-wrapper" ref={this.imageRef}>
          <img
            className="radius__background"
            ref={this.hRef}
            alt=""
            src={dlugabackground}
          />
        </div>

        <img
          className="radius__image"
          alt=""
          src={this.state.lightOn ? imageLight : image}
          onMouseEnter={e => this.enterMouse(e)}
          onMouseLeave={e => this.leaveMouse(e)}
          onMouseMove={e => this.moveMouse(e)}
          ref={this.imageInside}
        />
      </section>
    );
  }
}

export default RadiusSection;
