import React from "react";
import s from "./ApartmentsListPage.scss";
import Search from "../Search/";
import ApartmentList from "../ApartmentList/";
import ContactSection from "../ContactSection/";

export default class ApartmentsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choosed:false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  chooseFlatToForm = (flat) => {
    let obj = {flatNumber: flat};
    this.setState({choosed: obj})
  }

  render() {
    return (
      <div className="lp-apartments-list-page">
        <Search
          searchParams={this.props.match.params}
          redirectTo={this.props.redirectTo}
        />
        <ApartmentList searchParams={this.props.match.params} chooseFlatToForm={this.chooseFlatToForm} />
        <ContactSection
        params={this.state.choosed ? this.state.choosed : false}
        />
      </div>
    );
  }
}
