
import store from "../../store/index";
import { connect } from "react-redux";

import ContactForm from "./ContactForm";

const mapStateToProps = state => {
  return { flats: state.flats };
};

export default connect(mapStateToProps)(ContactForm);