import React from "react";
import gallery1 from "../../assets/images/dluga15-exterior-tram.jpg";
import gallery2 from "../../assets/images/dluga15-exterior-ujecie6-01.jpg";
import gallery3 from "../../assets/images/dluga15-exterior-ujecie6-02.jpg";
import gallery4 from "../../assets/images/dluga15-exterior-03-new.jpg";
import icon from "../../assets/icons/accent.svg";
import s from "./Gallery.scss";
import { NavLink } from "react-router-dom";

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
      image: false,
      id: 0,
      rotate: 0,
    };

    this.photos = [
      { id: 0, title: "", image: gallery1 },
      { id: 1, title: "", image: gallery2 },
      { id: 2, title: "", image: gallery3 },
      { id: 3, title: "", image: gallery4 },
    ];
    this.id = 0;
    this.previewPhotos = [];
  }

  openImage = (index, element) => {
    this.setState({ isOpen: true, image: element, id: index });

    this.id = index;
  };
  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.closeImage();
    }
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.escFunction);
    window.scrollTo(0, 0);
  };
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction);
  };

  closeImage = () => {
    this.setState({ isOpen: false });
  };

  seeNextImage = () => {
    let photos = this.photos;

    this.setState({
      rotate: 0,
    });
    if (this.id === photos.length - 1) {
      this.id = 0;
    } else {
      this.id = this.id + 1;
    }
  };
  seePreviousImage = () => {
    let photos = this.photos;

    this.setState({
      rotate: 0,
    });
    if (this.id === 0) {
      this.id = photos.length - 1;
    } else {
      this.id = this.id - 1;
    }
  };

  sortFunction = (a, b) => {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateA < dateB ? 1 : -1;
  };

  render() {
    let previewPhotos = this.photos.slice(0, 4);
    this.previewPhotos = previewPhotos;

    return (
      <section className="lp-gallery">
        <div className="container gallery_container">
          <h2 className="gallery__title title-basic">Galeria</h2>
          <img
            src={icon}
            alt="icon"
            className="af-icon-accent gallery__icon"
          ></img>

          <p className="gallery__text text-basic">
            Wielorodzinne budynki łączą w sobie nowoczesność, wysoki standard
            wykonania i wyraźną dbałość o każdy, najmniejszy szczegół. Zobacz,
            jak będzie prezentować się Twoje nowe mieszkanie!
          </p>

          <div className="gallery__main row">
            {previewPhotos.map((element, index) => (
              <div
                key={"small" + element.id}
                className="gallery-image-box col-6 col-s-12"
              >
                <img
                  onClick={() => this.openImage(index, element)}
                  className="gallery-image"
                  src={element.image}
                  alt={element.title}
                />
              </div>
            ))}
          </div>
          <NavLink to="/apartamenty" className="button-basic gallery__button">
            Wybierz mieszkanie
          </NavLink>
        </div>

        {this.state.isOpen ? (
          <div className="image__container-outside">
            <div className="image__container">
              <div className="image__container-inside">
                <button
                  onClick={() => this.closeImage()}
                  className="button-basic gallery-button gallery-button-close"
                >
                  <span className="af-icon-times-solid" />
                </button>

                <button
                  onClick={() => this.seePreviousImage()}
                  className="button-basic gallery-button gallery-button-left "
                >
                  <span className="af-icon-angle-left-solid"></span>
                </button>

                {this.photos.map((element, index) =>
                  index === this.id ? (
                    <img
                      key={"full" + element.id}
                      className="gallery-image"
                      src={element.image}
                      alt={element.title}
                    ></img>
                  ) : null
                )}

                <button
                  onClick={() => this.seeNextImage()}
                  className="button-basic gallery-button gallery-button-right"
                >
                  <span className="af-icon-angle-right-solid"></span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}
