import React from "react";
import "./LocalizationView.scss";

export default class LocalizationView extends React.Component {
  render() {
    return (
      <section className="lp-localization-view">
       
        <div className="localization-view__container container"></div>
      
      </section>
    );
  }
}
