import store from "../../store/index";
import { connect } from "react-redux";

import FloorViewLabel from "./FloorViewLabel";

const mapStateToProps = state => {
  return { flats: state.flats };
};

export default connect(mapStateToProps)(FloorViewLabel);
