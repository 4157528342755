import React from "react";
import "./Estate.scss";

export default class Estate extends React.Component {
  render() {
    return (
      <section className="lp-estate">
    
      </section>
    );
  }
}
