import React, {Component} from 'react';
const uuidv4 = require('uuid/v4');

export default class Input extends Component {

  constructor(props) {
    super(props);
    this.id = uuidv4();
    this.state = {
    }
  }

  render() {

    return (<div className='radio__wrapper'>
      <input type="radio"
      id={this.id}
      value={this.props.value}
      checked={this.props.isChecked}
      name={this.props.name}
      onClick={this.props.handleRadioChange}
      />
      <label htmlFor={this.id}>{this.props.value}</label>
      </div>
  )
  }
}
