import React from "react";
import s from "./ListMobileItem.scss";
import {Link} from "react-router-dom";
import {NavHashLink as NavLink} from "react-router-hash-link";

const uuidv4 = require("uuid/v4");

function fixM2(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(2)).replace(
    ".",
    ","
  );
}

function numberWithCommas(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(2))
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(",", " ")
    .replace(".", ",");
}

function fixM0(number) {
  return String(Number(String(number).replace(",", ".")).toFixed(0));
}

export default class ListMobileItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isApartmentChecked: false,
      moreInfo: false,
    };
    this.uuid = uuidv4();
  }

  toggleChange = e => {
    this.setState({
      isApartmentChecked: !this.state.isApartmentChecked,
    });
  };

  componentWillMount = () => {
    if ("localStorage" in window) {
      const checkboxStayChecked = JSON.parse(
        window.localStorage.getItem(`send-apartment ${this.props.num}`)
      );
      this.setState(checkboxStayChecked);
    }
  };

  componentWillUnmount = () => {
    localStorage.setItem(
      `send-apartment ${this.props.num}`,
      JSON.stringify(this.state)
    );
  };

  showMore = () => {
    this.setState({moreInfo: !this.state.moreInfo});
  };
  scrollWithOffset = el => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;

      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    let storey = this.props.flat.storey;
    if (Number(this.props.flat.storey) == 0) storey = "parter";
    if (Number(this.props.flat.storey) > 0) storey = storey + " piętro";

    let flatStatus = "Wolne";
    let statusNumber = 1;

    if (this.props.flat.flatStatus == 2) {
      flatStatus = "Rezerwacja";
      statusNumber = 2;
    } else if (this.props.flat.flatStatus == 3) {
      flatStatus = "Sprzedane";
      statusNumber = 3;
    }

    let rooms = this.props.flat.rooms.slice(1, 2);

    return (
      <div
        className={
          "list-mobile__item" +
          (statusNumber == 2 ? " reservation" : "") +
          (statusNumber == 3 ? " sold" : "")
        }
      >
        <div className="list-mobile__title-wrapper">
          <div className="list-mobile__input-wrapper">
            <input
              onChange={e => {
                this.toggleChange(e);
                this.state.isApartmentChecked
                  ? this.props.clearApartmentsArray(this.props.num)
                  : this.props.sendFlatByForm(this.props.flat);
              }}
              checked={this.state.isApartmentChecked}
              id={"send-apartment " + this.props.num}
              className="send-apartment__checkbox"
              type="checkbox"
            />

            <label
              htmlFor={"send-apartment " + this.props.num}
              className="send-apartment__label"
            >
              <span className="af-icon-tick"></span>
            </label>
          </div>

          <h4 className="list-mobile__title"> {this.props.flat.flatNumber}</h4>
        </div>

        <p
          className={
            "list-mobile__text" +
            (statusNumber == 2 ? " reservation" : "") +
            (statusNumber == 3 ? " sold" : "")
          }
        >
          <span>Piętro</span>
          <span>{storey}</span>
        </p>

        <p
          className={
            "list-mobile__text" +
            (statusNumber == 2 ? " reservation" : "") +
            (statusNumber == 3 ? " sold" : "")
          }
        >
          <span>Pokoje</span>
          <span>{rooms}</span>
        </p>

        <p
          className={
            "list-mobile__text" +
            (statusNumber == 2 ? " reservation" : "") +
            (statusNumber == 3 ? " sold" : "")
          }
        >
          <span>Powierzchnia</span>
          <span>
            {fixM2(this.props.flat.area)} m<sup>2</sup>
          </span>
        </p>

        <p
          className={
            "list-mobile__text" +
            (statusNumber == 2 ? " reservation" : "") +
            (statusNumber == 3 ? " sold" : "")
          }
        >
          <span>Status</span>
          <span>{flatStatus}</span>
        </p>

        {this.state.moreInfo ? (
          <div className="list-mobile__more-box">
            <p
              className={
                "list-mobile__text" +
                (statusNumber == 2 ? " reservation" : "") +
                (statusNumber == 3 ? " sold" : "")
              }
            >
              <span>Cena</span>
              <span>
                {this.props.flat.flatStatus == 3
                  ? " - "
                  : this.props.flat.flatStatus == 2
                  ? " - "
                  : this.props.flat.brutto
                  ? numberWithCommas(this.props.flat.brutto) + " zł"
                  : " - "}

                {/* <NavLink
                  className="list-mobile__send"
                  scroll={el => {
                    this.scrollWithOffset(el);
                  }}
                  onClick={e => {
                    e.preventDefault();
                    this.props.chooseFlatToForm(this.props.flat.flatNumber);
                  }}
                  exact
                  to={"/#kontakt"}
                >
                  {" "}
                  <span> Zapytaj o cenę</span>
                </NavLink> */}
              </span>
            </p>

            <p
              className={
                "list-mobile__text" +
                (statusNumber == 2 ? " reservation" : "") +
                (statusNumber == 3 ? " sold" : "")
              }
            >
              <span>Balkon</span>
              <span>
                {fixM0(this.props.flat.balconyArea) == 0 ? (
                  fixM0(this.props.flat.loggia) == 0 ? (
                    "-"
                  ) : (
                    <span>
                      {fixM2(this.props.flat.loggia)} m<sup>2</sup>
                    </span>
                  )
                ) : (
                  <span>
                    {fixM2(this.props.flat.balconyArea)} m<sup>2</sup>
                  </span>
                )}
              </span>
            </p>

            <p
              className={
                "list-mobile__text" +
                (statusNumber == 2 ? " reservation" : "") +
                (statusNumber == 3 ? " sold" : "")
              }
            >
              <span>Ogród</span>

              <span>
                {fixM0(this.props.flat.gardenArea) == 0 ? (
                  "-"
                ) : (
                  <span>
                    {fixM2(this.props.flat.gardenArea)} m<sup>2</sup>
                  </span>
                )}
              </span>
            </p>

            <p
              className={
                "list-mobile__text" +
                (statusNumber == 2 ? " reservation" : "") +
                (statusNumber == 3 ? " sold" : "")
              }
            >
              <span>PDF</span>
              <span>
                <a
                  className="download-pdf-icon"
                  target="_blank"
                  href={
                    "/pdf/" +
                    String(this.props.flat.flatNumber).split("/").join("_") +
                    ".pdf"
                  }
                >
                  {this.props.flat.flatNumber}.pdf
                </a>
              </span>
            </p>

            <p
              className={
                "list-mobile__text" +
                (statusNumber == 2 ? " reservation" : "") +
                (statusNumber == 3 ? " sold" : "")
              }
            >
              <span>Zapytanie</span>
              <span>
                <NavLink
                  className="list-mobile__send"
                  scroll={el => {
                    this.scrollWithOffset(el);
                  }}
                  onClick={e => {
                    e.preventDefault();
                    this.props.chooseFlatToForm(this.props.flat.flatNumber);
                  }}
                  exact
                  to={"/#kontakt"}
                >
                  {" "}
                  <span className="af-icon-envelope" />
                  <span> Wyślij</span>
                </NavLink>
              </span>
            </p>
          </div>
        ) : null}

        <p className="list-mobile__text no-border-bottom">
          <span>
            {this.state.moreInfo ? (
              <button className="list-mobile__more" onClick={this.showMore}>
                <span className="af-icon-minus-square"></span>
                <span>Mniej</span>
              </button>
            ) : (
              <button className="list-mobile__more" onClick={this.showMore}>
                <span className="af-icon-plus-square"></span>
                <span>Więcej</span>
              </button>
            )}
          </span>
          <span>
            <Link
              className="list-mobile__see"
              to={
                "/apartamenty/" +
                this.props.flat.buildingNumber +
                "/P" +
                this.props.flat.storey +
                "/" +
                String(this.props.flat.flatNumber).replace(/\//g, ".")
              }
            >
              Zobacz
            </Link>
          </span>
        </p>
      </div>
    );
  }
}
