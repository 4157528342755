import React from "react";
import s from "./CentrumSection.scss";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
// import dlugabackground from "../../assets/images/centrum-background.webp";
import dlugabackground from "../../assets/images/space-background.webp";
// import dlugabackgroundA from "../../assets/images/centrum-background.jpg";
import dlugabackgroundA from "../../assets/images/space-background.jpg";

export default class CentrumSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="lp-centrum">
        <div className="centrum__container container-new row">
          <div className="centrum__left col-4 col-m-12">
            <h2 className="centrum__title text-basic">Tak blisko centrum</h2>

            <p className="centrum__text text-basic">
              Sprawna komunikacja z centrum dzięki nowemu tramwajowi
            </p>

            <Link
              to="/lista-apartamentow"
              className="centrum__button button-basic button-white"
            >
              Lista mieszkań
            </Link>
          </div>

          <div className="centrum__right col-m-12">
            <picture className="centrum__background">
              <source srcset={dlugabackground} type="image/webp" />
              <source srcset={dlugabackgroundA} type="image/jpeg" />
              <img src={dlugabackgroundA} className="centrum__background" />
            </picture>
          </div>
        </div>
      </section>
    );
  }
}
