import React from "react";

import "./InvestmentPage.scss";
import MainBanner from "../MainBanner/";
import About from "../About/";
import Area from "../Area/Area";
import ContactSection from "../ContactSection";
import Quality from "../Quality/";
import Detail from "../Detail/";
import Estate from "../Estate/";
import mainbackground from "../../assets/images/dluga15-exterior-03-new.jpg";
import Benefits from "../Benefits/";

export default class InvestmentPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div className="lp-investment-page">
        <MainBanner background={mainbackground} scrollItem="#area" />

        <Area />
        <Quality />
        <Detail />
        <Benefits />
        <Estate />
        {/* <About
          title="Hossanova"
          text="Nasze projekty budowlane to przemyślane koncepcje w których bierzemy pod uwagę każde istotne detale, by spełnić wymagania przyszłych właścicieli. Spośród lokalizacji, wybieramy te, które odpowiadają naszym wysokim standardom. Zapraszamy do współpracy właścicieli gruntu, posiadających wiedzę i informację o atrakcyjnym miejscu, które spełnia nasze wymagania."
        /> */}
        <ContactSection />
      </div>
    );
  }
}
