import React, {Component} from 'react';
const uuidv4 = require('uuid/v4');
export default class CheckedFilter extends Component {

  constructor(props) {
    super(props);
    this.id = uuidv4();
    this.state = {
      isChecked: false
    }
  }

  showCheckedMark = () => {
    this.setState({isChecked: !this.state.isChecked})
  }

  setFalse = () => {
    this.setState({isChecked: false})
  }

  render() {
    return (
      <div>
        <div className='checked'
             style={{visibility: this.props.isDisabled || this.state.isChecked ? 'visible' : 'hidden'}}>
             <span className={'af-icon-tick'} />
        </div>
        <button onClick={() => {
          this.showCheckedMark();
          this.props.toggleSort(this.props.item, this.props.num, this.setFalse);
        }}
        >{this.props.text}</button>
      </div>
    )
  }
}
