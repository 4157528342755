import {ADD_TEXT, ADD_BASE, ADD_LAST_URL} from "../constants/action-types";

let initialState = {
  text: [],
  meta: [],
  blogList: [],
  ssr: false,
  base: {},
  flats: []
};

if (
  typeof window.preloadedInitialState !== "undefined" &&
  window.preloadedInitialState
) {
  initialState = Object.assign({}, initialState, window.preloadedInitialState);
}

initialState["lastUrl"] = false;

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEXT:
      return {...state, text: [...state.text, action.newText]};
    case ADD_BASE:
      return {...state, base: action.newBase};
    case ADD_LAST_URL:
      return {...state, lastUrl: action.lastUrl};
    default:
      return state;
  }
};

export default rootReducer;
