import React, {Component} from 'react';
const uuidv4 = require('uuid/v4');

export default class StatusFilter extends Component {

  constructor(props) {
    super(props);
    this.id = uuidv4();
    this.state = {
      isChecked: false
    }
  }

  showCheckedMark = () => {
    this.setState({isChecked: !this.state.isChecked})
  }

  setFalse = () => {
    this.setState({isChecked: false})
  }

  render() {
    return (
      <div>
        <div className='checked'
             style={{visibility: this.props.isChecked ? 'visible' : 'hidden'}}
             >
             <span className={'af-icon-tick'} />
        </div>
        <button onClick={() => {
          this.props.sort();
        }}
        >{this.props.text}</button>
      </div>
    )
  }
}
