import React, {Component} from "react";
import s from "./BuildingsBannerLabel.scss";

export default class BuildingsBannerLabel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getFreeFlatsNumber = (buildingNum, storeyNum) => {
    storeyNum = storeyNum.replace("P", "");

    if (buildingNum == "L") {
      let newArray = this.props.flats.filter(
        element =>
          element.buildingNumber == "K1" &&
          element.storey == storeyNum &&
          element.flatStatus == 1
      );
      return newArray.length;
    } else if (buildingNum == "T") {
      let newArray = this.props.flats.filter(
        element =>
          (element.buildingNumber == "K2" || element.buildingNumber == "K3") &&
          element.storey == storeyNum &&
          element.flatStatus == 1
      );
      return newArray.length;
    }
  };

  render() {
    let buildingNumber = this.props.hoveredBuilding.split("_")[0];
    let storey = this.props.hoveredBuilding.split("_")[1].replace('P',"");

    let labelX = this.props.labelX + 20;
    let labelY = this.props.labelY + 20;

      return (
        <div className="buildings-banner__label" style={{top:`${labelY}px`, left:`${labelX}px`}}>
          <h2 className="buildings-banner__label-title">
            {storey} Piętro
          </h2>

          <p className="buildings-banner__label-text">
            {this.getFreeFlatsNumber(buildingNumber,storey)} wolnych
          </p>
        </div>
      );
    }
}
