import store from "../../store/index";
import { connect } from "react-redux";

import Search from "./Search";

const mapStateToProps = state => {
  return { ssr: state.ssr };
};

export default connect(mapStateToProps)(Search);
