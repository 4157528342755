import store from "../../store/index";
import { connect } from "react-redux";

import ApartmentList from "./ApartmentList";

const mapStateToProps = state => {
  return { flats: state.flats, lastUrl: state.lastUrl };
};

export default connect(mapStateToProps)(ApartmentList);
