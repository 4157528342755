import React from "react";
import "./Quality.scss";
import icon from "../../assets/icons/accent.svg";

export default class Quality extends React.Component {
  render() {
    return (
      <section className="lp-quality">
        <div className="quality__container container row">
          <div className="quality__right col-6 col-m-12">
            <h2 className="quality__title title-basic">
            Wyjątkowa jakość i wykonanie szczegółów.
            </h2>
            <p className="quality__text text-basic">
              <span className="af-icon-list-item" />
              <span className="text">
                Materiały konstrukcyjne i budowlane najwyższej jakości.
              </span>
            </p>

            <p className="quality__text text-basic">
              <span className="af-icon-list-item" />
              <span className="text">
              Wysoki i funkcjonalny standard wykończenia części wspólnych.
              </span>
            </p>

            <p className="quality__text text-basic">
              <span className="af-icon-list-item" />
              <span className="text">
              Cichobieżne windy renomowanego producenta.              </span>
            </p>

            <p className="quality__text text-basic">
              <span className="af-icon-list-item" />
              <span className="text">
              Duże, jasne okna wykonane z drewna i aluminium.              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
