import React, {Component} from "react";

import s from "./Privacy.scss";

export default class Privacy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: false
    };
  }

  render() {
    let text = this.props.text.find(
      item => item._id === "5e734cfcc1499a392fc22894"
    );

    return (
      <section className="lp-privacy container">
        <h2 className="title title-basic">{text.title}</h2>
        <div
          className="text text-basic"
          dangerouslySetInnerHTML={{__html: text.description}}
        ></div>
      </section>
    );
  }
}
