import React, {Component} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import s from "./ApartmentNewSlick.scss";

import image1 from "../../assets/images/main-background.webp";
import image1a from "../../assets/images/main-background.jpg";

import image2 from "../../assets/images/dluga15-exterior-04.webp";
import image2a from "../../assets/images/dluga15-exterior-04.jpg";

import image3 from "../../assets/images/localization-main-background.webp";
import image3a from "../../assets/images/localization-main-background.jpg";

import image4 from "../../assets/images/gallery1.webp";
import image4a from "../../assets/images/gallery1.jpg";

import Slider from "react-slick";

export default class ApartmentNewSlick extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source1: "",
      source2: "",
      id: 0,
    };

    this.myImg1 = React.createRef();
    this.myImg2 = React.createRef();
    this.slider = React.createRef();
  }

  componentDidMount = () => {
    if (this.props !== "undefined" && Object.keys(this.props).length > 0) {
      this.slider.current.slickGoTo(0);

      this.setState({
        source1:
          "/3d/" +
          String(this.props.thisFlat.flatNumber).split("/").join("-") +
          ".jpg",

        source2:
          "/2d/" +
          String(this.props.thisFlat.flatNumber).split("/").join("_") +
          ".jpg",
        id: 0,
      });
    }

    document.addEventListener("keydown", this.escFunction);
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.escFunction);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      if (nextProps !== "undefined" && Object.keys(nextProps).length > 0) {
        this.slider.current.slickGoTo(0);

        this.setState({
          source1:
            "/3d/" +
            String(nextProps.thisFlat.flatNumber).split("/").join("-") +
            ".jpg",

          source2:
            "/2d/" +
            String(nextProps.thisFlat.flatNumber).split("/").join("_") +
            ".jpg",
          id: 0,
        });
      }
    }
  };

  onError3d = () => {
    this.setState({
      source1:
        "/2d/" +
        String(this.props.thisFlat.flatNumber).split("/").join("_") +
        ".jpg",
    });
  };

  onError2d = () => {
    this.setState({
      source2: image1a,
    });
  };

  openImage = (index, element) => {
    this.setState({isOpen: true, image: element, id: index});
  };
  escFunction = event => {
    if (event.keyCode === 27) {
      this.closeImage();
    }
  };

  closeImage = () => {
    this.setState({isOpen: false});
  };

  seeNextImage = imagesArr => {
    let photos = imagesArr;

    if (this.state.id === photos.length - 1) {
      this.setState({id: 0});
    } else {
      this.setState({id: this.state.id + 1});
    }
  };
  seePreviousImage = imagesArr => {
    let photos = imagesArr;
    if (this.state.id === 0) {
      this.setState({id: photos.length - 1});
    } else {
      this.setState({id: this.state.id - 1});
    }
  };

  render() {
    let imagesArr = [
      {
        id: 0,
        image: this.state.source1,
        imageA: this.state.source1,
        ref: true,
        refName: this.myImg1,
        error: true,
      },
      {
        id: 1,
        image: this.state.source2,
        imageA: this.state.source2,

        ref: true,
        error2: true,
        refName: this.myImg2,
      },
      {
        id: 2,
        image: image2,
        imageA: image2a,
      },
      {
        id: 3,
        image: image3,
        imageA: image3a,
      },
      {
        id: 4,
        image: image4,
        imageA: image4a,
      },
    ];

    const settings = {
      customPaging(i) {
        return (
          <a className="custom-paging_item">
            <img src={`${imagesArr[i].imageA}`} alt="" />
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      className: "center",
      centerMode: true,
      centerPadding: "0",
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 700,
      responsive: [
        {
          breakpoint: 1079,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };

    return (
      <div className="apartment-new-slick">
        <Slider {...settings} ref={this.slider}>
          {imagesArr.map((item, index) => (
            <div
              key={`s${item.id}`}
              className="apartment-new-slick-child"
              onClick={() => this.openImage(index, item)}
            >
              <img
                myRef={item.ref ? item.refName : null}
                src={item.imageA}
                onError={
                  item.error
                    ? () => this.onError3d()
                    : item.error2
                    ? () => this.onError2d()
                    : null
                }
                alt=""
                className="apartment-new-slick__image"
              />
            </div>
          ))}
        </Slider>

        {this.state.isOpen ? (
          <div className="image__container-outside">
            <div className="image__container">
              <div className="image__container-inside">
                <button
                  onClick={() => this.closeImage()}
                  className="button-basic gallery-button gallery-button-close"
                >
                  <span className="af-icon-times-solid" />
                </button>

                <button
                  onClick={() => this.seePreviousImage(imagesArr)}
                  className="button-basic gallery-button gallery-button-left "
                >
                  <span className="af-icon-angle-left-solid"></span>
                </button>

                {imagesArr.map((element, index) =>
                  index === this.state.id ? (
                    <img
                      key={"full" + element.id}
                      className="gallery-image"
                      myRef={element.ref ? element.refName : null}
                      src={element.imageA}
                      onError={
                        element.error
                          ? () => this.onError3d()
                          : element.error2
                          ? () => this.onError2d()
                          : null
                      }
                      alt=""
                    ></img>
                  ) : null
                )}

                <button
                  onClick={() => this.seeNextImage(imagesArr)}
                  className="button-basic gallery-button gallery-button-right"
                >
                  <span className="af-icon-angle-right-solid"></span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
