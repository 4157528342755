import React from "react";
import "./Meeting.scss";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import mainbackground from "../../assets/images/apartments-page-background.jpg";
import MeetingContactForm from "../MeetingContactForm/";

export default class Meeting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  scrollWithOffset = (el) => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    let meetingBenefits = [
      {
        icon: "meeting-comfort",
        title: "Komfort",
        text: "Łączysz się wygodnie, bezpośrednio z dowolnego miejsca.",
      },
      {
        icon: "meeting-time",
        title: "Oszczędność czasu",
        text: "Nie tracisz czasu na dojazd, a w razie konieczności spotkanie możesz przełożyć.",
      },
      {
        icon: "meeting-calculator",
        title: "Pełna oferta i doradztwo",
        text: "Biuro Sprzedaży przygotuje dla Ciebie nie tylko ofertę doradzi w kwesti kredytu.",
      },
      {
        icon: "meeting-safety",
        title: "Bezpieczeństwo",
        text: "Wszystko w jednym miejscu. Bez wychordzenia z domu.",
      },
      // {
      //   icon: "meeting-pen",
      //   title: "Cyfrowy podpis",
      //   text: "Zawarcie umowy rezerwacyjnej jest możliwe z cyfrowym podpisem.",
      // },
    ];

    let video = [
      {
        icon: "meeting-mail",
        title: "1.",
        text: "Na maila otrzymasz zaproszenie do rozmowy. Kliknij w link.",
      },
      {
        icon: "meeting-join",
        title: "2.",
        text: "Wybierz opcję: Dołącz w przeglądarce.",
      },
      {
        icon: "meeting-camera",
        title: "3.",
        text: "Włącz kamerę i mikrofon. Kliknij: Zezwalaj.",
      },
      {
        icon: "meeting-chat",
        title: "4.",
        text: "Wpisz swoje imię i nazwisko i dołącz do spotkania!",
      },
    ];

    return (
      <section className="lp-meeting">
        <div className="meeting__banner">
          <div className="meeting__container container">
            <h2 className="meeting__title title-basic">
              <span className="first">Umów się na spotkanie online</span>
            </h2>
            <h2 className="meeting__title title-basic">
              <span>z naszym doradcą!</span>
            </h2>
            <p className="meeting__text text-basic">
              Połącz się z naszym doradcą on-line i poznaj ofertę naszych
              mieszkań.
            </p>
            <p className="meeting__text bold text-basic">
              {" "}
              To łatwiejsze niż myślisz!
            </p>

            <NavLink
              scroll={(el) => {
                this.scrollWithOffset(el);
              }}
              onClick={(e) => {
                if (window && window.location.pathname === "/spotkanie") {
                  e.preventDefault();
                }
              }}
              to={"/#spotkanie"}
              className="meeting__form-link button-basic"
            >
              Wypełnij formularz
            </NavLink>
          </div>
        </div>

        <div className="meeting__benefits">
          <div className="meeting__container container">
            <h2 className="meeting__title title-basic">Dlaczego warto?</h2>
            <p className="meeting__text text-basic">
              Poznaj korzyści naszego innowacyjnego rozwiązania:
            </p>

            <div className="meeting__benefits-box row">
              {meetingBenefits.map((element, index) => (
                <div
                  className="meeting__benefit col-3 col-m-6 col-s-12"
                  key={"meetingBenefit" + index}
                >
                  <span
                    className={`af-icon-${element.icon} meeting__benefit-icon`}
                  />
                  <span className="meeting__benefit-text text-basic bold">
                    {element.title}
                  </span>
                  {/* <span className="meeting__benefit-text text-basic">
                    {element.text}
                  </span> */}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="meeting__contact" id="spotkanie">
          <div className="meeting__container container">
            <h2 className="meeting__title title-basic">
              Chcesz zamieszkać na Dugiej?
            </h2>
            <p className="meeting__text text-basic">
              Masz pytania? Napisz do nas!
            </p>

            <MeetingContactForm />
          </div>
        </div>

        <div className="meeting__benefits">
          <div className="meeting__container container">
            <h2 className="meeting__title title-basic">
              Jak dołączyć do wideorozmowy?
            </h2>
            <p className="meeting__text text-basic">
              Wykonaj 4 proste kroki, aby połączyć się online z naszym Doradcą.
            </p>

            <div className="meeting__benefits-box row">
              {video.map((element, index) => (
                <div
                  className="meeting__benefit col-3 col-m-6 col-s-12"
                  key={"meetingStep" + index}
                >
                  <span
                    className={`af-icon-${element.icon} meeting__benefit-icon`}
                  />
                  <span className="meeting__benefit-text text-basic bold">
                    {element.title}
                  </span>
                  <span className="meeting__benefit-text text-basic">
                    {element.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
