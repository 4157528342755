import React from "react";
import "./LocalizationInfo.scss";
import Slider from "react-slick";
import image1 from "../../assets/images/localization-info1.jpg";
import image2 from "../../assets/images/localization-info2.jpg";
import image3 from "../../assets/images/localization-info3.jpg";

import LocalizationSliderItem from "./LocalizationSliderItem";

export default class LocalizationInfo extends React.Component {
  render() {
    let images = [
      {
        id: 0,
        title: "",
        text: "",
        imgSrc: image1,
      },
      {
        id: 1,
        title: "",
        text: "",
        imgSrc: image2,
      },
      {
        id: 2,
        title: "",
        text: "",
        imgSrc: image3,
      },
    ];

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "50px",
      slidesToShow: 2,
      slidesToScroll: 2,
      speed: 700,
      responsive: [
        {
          breakpoint: 1079,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 740,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: "40px",
          },
        },
      ],
    };

    return (
      <section className="lp-localization-info">
        <div className="localization-info__container container row">
          <div className="localization-info__left col-6 col-m-12">
            <h2 className="localization-info__title title-basic">
              Okolica dla Ciebie
            </h2>
            <p className="localization-info__text text-basic">
              Sklepy, restauracje, apteki, usługi - wszystko to znajdziesz w
              okolicy.
            </p>
          </div>

          <div className="localization-info__main col-12">
            <div className="localization-info__slider-parent">
              <Slider {...settings}>
                {images.map((item, index) => (
                  <div key={"lok" + index} className="image-parent">
                    <LocalizationSliderItem data={item} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
