import React, { Component } from "react";
import { BrowserRouter, Link, NavLink } from "react-router-dom";
import s from "./Footer.scss";

import hossanova from "../../assets/images/hossanova-logo-new.png";
import brochow from "../../assets/images/logo-brochow.png";
import zielonemiasto from "../../assets/images/logo-zielone.png";
import nepalska from "../../assets/images/logo-nepalska.png";
import opoczynska from "../../assets/images/logo-opoczynska.png";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPhone: false,
      showEmail: false,
    };
  }

  render() {
    return (
      <footer className="lp-footer">
        <div className="footer__container container row">
          <div className="footer__left col-4 col-m-12">
            <a href="https://hossanova.pl/" className="footer__logo-link">
              <img
                src={hossanova}
                alt="hossanova-logo"
                className="footer__logo"
              />
            </a>
            <p className="footer__text text-basic">
              Filary naszej działalności to jakość, design, funkcjonalność i
              lokalizacja. Dokładamy wszelkich starań, by w naszych inwestycjach
              każdy z nich był na jak najwyższym poziomie.
            </p>
          </div>

          <div className="footer__main col-5 col-m-12">
            <h2 className="footer__title title-basic">Nasze inwestycje</h2>
            <div className="footer__investments">
              <a
                className="footer__investment-link"
                href="https://hossanova.pl/blog/2020/08/06/zielony-brochow-inwestycja-zrealizowana/"
                target="_blank"
              >
                <img
                  className="investment-logo"
                  src={brochow}
                  alt="zielony Brochów"
                />
              </a>
              <a
                className="footer__investment-link"
                href="https://hossanova.pl/blog/2020/07/29/opoczynska-inwestycja-zrealizowana/"
                target="_blank"
              >
                <img
                  className="investment-logo"
                  src={opoczynska}
                  alt="opoczyńska"
                />
              </a>
              <a
                className="footer__investment-link"
                href="https://hossanova.pl/blog/2020/04/02/nepalska-inwestycja-zrealizowana/"
                target="_blank"
              >
                <img
                  className="investment-logo"
                  src={nepalska}
                  alt="nepalska"
                />
              </a>
              <a
                className="footer__investment-link"
                href="https://hossanova.pl/blog/2023/03/01/zielone-miasto-inwestycja-zrealizowana/"
                target="_blank"
              >
                <img
                  className="investment-logo"
                  src={zielonemiasto}
                  alt="zielone miasto"
                />
              </a>
            </div>
          </div>

          <div className="footer__right col-3 col-m-12">
            <div className="footer__links">
              <Link to="/polityka-prywatnosci" className="link text-basic">
                Polityka prywatności
                <span className="af-icon-arrow-right"></span>
              </Link>
              <a
                className="link text-basic"
                href="https://hossanova.pl/wykonczenie-pod-klucz/"
                target="_blank"
              >
                Wykończenie pod klucz
                <span className="af-icon-arrow-right"></span>
              </a>

              <a
                className="link text-basic"
                href="https://hossanova.pl/poznaj-nasze-osiedla/nasze-inwestycje/"
                target="_blank"
              >
                Nasze inwestycje<span className="af-icon-arrow-right"></span>
              </a>
              <a
                className="link text-basic"
                href="https://hossanova.pl/poznaj-nasze-osiedla/kontakt/"
                target="_blank"
              >
                Kontakt<span className="af-icon-arrow-right"></span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
