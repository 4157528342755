import React from "react";
import s from "./MainBanner.scss";
import {NavHashLink as NavLink} from "react-router-hash-link";
import BannerSideContact from "../BannerSideContact/";

export default class MainBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollWithOffset = el => {
    let offset;
    if (window && window.innerWidth && window.innerWidth <= 610) {
      // offset = 120;
      offset = 70;
    } else {
      offset = 85;
    }

    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };
  // background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
  render() {
    let bannerStyle = {
      background: `rgba(0,0,0, 1)`,
   
      backgroundImage: `url("${this.props.background}")`,
      background: `-moz-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${this.props.background}") `,
      background: `-webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(0,0,0, 0.35)),
        color-stop(36%, rgba(0, 0, 0, 0.35)),
        color-stop(65%, rgba(0, 0, 0, 0.3)),
        color-stop(100%, rgba(0,0,0, 0.1))
      ),
      url("${this.props.background}") `,
      background: `-webkit-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${this.props.background}") `,
      background: `-o-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0,0.1) 100%
      ),
      url("${this.props.background}") `,
      background: `-ms-linear-gradient(
        top,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${this.props.background}") `,
      background: `linear-gradient(
        to bottom,
        rgba(0,0,0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 36%,
        rgba(0, 0, 0, 0.3) 65%,
        rgba(0,0,0, 0.1) 100%
      ),
      url("${this.props.background}") `,
      backgroundSize: `cover`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `center`,
    };

    return (
      <section className="lp-banner" style={bannerStyle}>
        <BannerSideContact />

        <div className="banner__container container">
          <div className="banner__media">
            <NavLink
              className="contact__icon-box"
              smooth
              scroll={el => {
                this.scrollWithOffset(el);
              }}
              onClick={e => {
                if (
                  window.location.pathname == "/" ||
                  window.location.pathname == "/lokalizacja" ||
                  window.location.pathname == "/inwestycja"
                ) {
                  e.preventDefault();
                }
              }}
              to={this.props.scrollItem}
            >
              <span className="af-icon-scroll contact__icon"></span>
            </NavLink>
          </div>
        </div>
      </section>
    );
  }
}
