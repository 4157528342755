import React from "react";
import "./FloorView.scss";
import {Redirect, withRouter} from "react-router-dom";
import floorImg from "../../assets/images/L_P0.svg";
import SVG from "svg.js";
import FloorViewLabel from "../FloorViewLabel/";
import Loader from "../Loader/";

const uuidv4 = require("uuid/v4");
const flatNumberFromId = str => String(str).split("_x2F_").join(".");
const idFromflatNumber = str => String(str).replace(/\./g, "_x2F_");

class FloorView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingNumber: "",
      storey: "",
      flatNumber: "",

      buildingsButtonsActive: false,
      floorsButtonsActive: false,
      apartmentTypesActive: false,
      loading: false,

      filteredApartments: [
        {type: "M1", isChecked: false, color: "#f9f093", class: "m1"},
        {type: "M2", isChecked: false, color: "#f8b67b", class: "m2"},
        // {type: "M1_1", isChecked: false, color: "#f3978c", class: "m1_"},
        {type: "M3", isChecked: false, color: "#ed7560", class: "m3"},
        {type: "M4", isChecked: false, color: "#e8452f", class: "m4"},
        {type: "U", isChecked: false, color: "#bec0e2", class: "u"},
      ],
    };

    this.svgParent = React.createRef();
    this.uuid = `svg-${uuidv4()}`;
    this.draw = false;
    this.lastSvgName = null;
    this.svgCallbacks = [];

    this.apartmensType = [
      {type: "M1", name: "1 pokojowe"},
      {type: "M2", name: "2 pokojowe"},
      // {type: "M1_1", name: "2 pokojowe *"},
      {type: "M3", name: "3 pokojowe"},
      {type: "M4", name: "4 pokojowe"},
      {type: "U", name: "Usługowe"},
    ];

    this.svgApartments = [];
  }

  showBuildingButtons = () => {
    this.setState({buildingsButtonsActive: !this.state.buildingsButtonsActive});
  };
  showFloorsButtons = () => {
    this.setState({floorsButtonsActive: !this.state.floorsButtonsActive});
  };
  showTypesButtons = () => {
    this.setState({apartmentTypesActive: !this.state.apartmentTypesActive});
  };

  componentDidMount = () => {
    if (this.props !== "undefined" && Object.keys(this.props).length > 0) {
      this.setState({
        buildingNumber: this.props.params.buildingNumber,
        storey: this.props.params.storey,
        flatNumber: this.props.params.flatNumber,
        hoveredFlat: this.props.params.flatNumber,
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      if (nextProps !== "undefined" && Object.keys(nextProps).length > 0) {
        this.setState({
          buildingNumber: nextProps.params.buildingNumber,
          storey: nextProps.params.storey,
          flatNumber: nextProps.params.flatNumber,
          hoveredFlat: nextProps.params.flatNumber,
        });
      }
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const svgName = `${this.convertBuildingNumber(this.state.buildingNumber)}_${
      this.state.storey
    }`;

    !this.lastSvgName && this.lastSvgName != svgName && this.makeSVG(svgName);
  }

  makeSVG = svgName => {
    var ajax = new XMLHttpRequest();
    // ajax.open("GET", floorImg, true);
    ajax.open("GET", `/floors/${svgName}.svg`, true);
    ajax.send();
    ajax.onload = e => {
      this.draw = SVG(this.uuid);
      let draw = this.draw;
      this.lastSvgName = svgName;

      draw.svg(ajax.responseText);

      this.apartmensType.forEach(element => {
        let item = draw.select(`#${element.type} polygon`);
        item.style({fill: "transparent", position: "relative"});
        this.svgApartments.push(item);

        this.markFlatStatus(element);

        item.each((i, j) => {
          let s = j[i];

          let group = draw.group().addClass("group-parent");
          const groupRect = group.node.getBoundingClientRect();
          const sRect = s.node.getBoundingClientRect();
          let text = draw
            .plain(flatNumberFromId(s.id()))
            .addClass("flat-text")
            .style({
              fill: "black",
              font: '12px "montserrat-medium", sans-serif',
            });

          const pos = {
            x: sRect.x - groupRect.x + sRect.width / 2,
            y: sRect.y - groupRect.y + sRect.height / 2,
          };

          text.attr({
            x: pos.x,
            y: pos.y,
            "dominant-baseline": "middle",
            "text-anchor": "middle",
          });

          group.add(text);
          group.before(draw.select("svg").first());
        });
      });

      if (this.svgApartments.length > 0) {
        this.svgApartments.forEach(item => {
          item.mouseover(e => {
            let flat = e.target.id.replace(/_x2F_/g, ".");
            this.setState({hoveredFlat: flat});

            draw.select("#" + e.target.id).style({
              stroke: "#af9b69",
              cursor: "pointer",
              "stroke-width": 50,
              transition: "0.3s",
            });
          });

          item.mouseout(e => {
            if (this.state.flatNumber) {
              let flatId = idFromflatNumber(this.state.flatNumber);
              if (flatId === e.target.id) {
                draw.select("#" + e.target.id).style({
                  stroke: "green",
                  "stroke-width": 50,
                });
              } else {
                draw.select("#" + e.target.id).style({
                  stroke: "transparent",
                  "stroke-width": 0,
                });
              }
            } else {
              draw.select("#" + e.target.id).style({
                stroke: "transparent",
                "stroke-width": 0,
              });
            }

            this.setState({hoveredFlat: this.props.params.flatNumber});
          });

          item.click(e => {
            let flat = e.target.id.replace(/_x2F_/g, ".");
            let building = flat.split(".")[0];
            let storey = flat.split(".")[1];

            if (e.target.parentElement.id === "U") {
              // console.log('brak karty')
            } else {
              this.props.history.push(
                `/apartamenty/${building}/${storey}/${flat}`
              );
            }

            this.colorFiltredApartments(this.state.filteredApartments);
            this.colorFlat(flat);
          });
        });
      }

      this.colorFlat(this.state.flatNumber);

      this.svgCallbacks.forEach(cb => {
        cb();
      });
      this.svgCallbacks = [];

      this.setState({loading: true});
    };
  };

  markFlatStatus = element => {
    let item = this.draw.select(`#${element.type} polygon`);

    item.each((i, j) => {
      let polygonToCheck = j[i].node.id;
      let idToCheck = String(polygonToCheck).split("_x2F_").join("/");
      let findedFlat = this.props.flats.find(
        flat => flat.flatNumber === idToCheck
      );

      if (findedFlat) {
        if (
          findedFlat.flatStatus !== undefined &&
          findedFlat.flatStatus === 2
        ) {
          this.draw
            .select(`#${element.type} polygon#${polygonToCheck}`)
            .style({fill: "#C0C6C7"});
        } else if (
          findedFlat.flatStatus !== undefined &&
          findedFlat.flatStatus === 3
        ) {
          this.draw
            .select(`#${element.type} polygon#${polygonToCheck}`)
            .style({fill: "#020202", opacity: "0.7"});
        } else {
          this.draw
            .select(`#${element.type} polygon#${polygonToCheck}`)
            .style({fill: "transparent"});
        }
      } else {
        this.draw
          .select(`#${element.type} polygon#${polygonToCheck}`)
          .style({fill: "transparent"});
      }
    });
  };

  clearAllFlatsFill = () => {
    this.apartmensType.forEach(group => {
      let element = this.draw.select(`#${group.type} polygon`);
      element.style({
        fill: "transparent",
      });

      this.markFlatStatus(group);
    });
  };

  clearAllFlatsStroke = () => {
    this.apartmensType.forEach(group => {
      let element = this.draw.select(`#${group.type} polygon`);
      element.style({
        stroke: "transparent",
        "stroke-width": 0,
      });
    });
  };

  colorFiltredApartments = filteredApartments => {
    if (this.draw) {
      if (filteredApartments) {
        this.clearAllFlatsFill();

        filteredApartments.forEach(item => {
          let element = this.draw.select(`#${item.type} polygon`);

          if (item.isChecked) {
            element.style({fill: item.color});

            element.each((i, j) => {
              let polygonToCheck = j[i].node.id;
              let idToCheck = String(polygonToCheck).split("_x2F_").join("/");

              let findedFlat = this.props.flats.find(
                flat => flat.flatNumber === idToCheck
              );

              if (findedFlat && findedFlat.flatStatus == 2) {
                this.draw
                  .select(`#${item.type} polygon#${polygonToCheck}`)
                  .style({fill: `url(#${item.type}_reservation)`});
              } else if (findedFlat && findedFlat.flatStatus == 3) {
                this.draw
                  .select(`#${item.type} polygon#${polygonToCheck}`)
                  .style({fill: `url(#${item.type}_sold)`});
              }
            });
          } else {
            this.markFlatStatus(item);
          }
        });
      }
    } else {
      // this.svgCallbacks.push(() => {
      //   this.colorFlat(filteredApartments);
      // });
    }
  };

  colorFlat = flatNumber => {
    if (this.draw) {
      if (flatNumber) {
        this.clearAllFlatsStroke();
        let flatId = idFromflatNumber(this.state.flatNumber);
        let flat = this.draw.select(`#${flatId}`);
        flat.style({
          stroke: "green",
          "stroke-width": 50,
        });
      }
    } else {
      this.svgCallbacks.push(() => {
        this.colorFlat(flatNumber);
      });
    }
  };

  convertBuildingNumber = num => {
    // K1 to budynek L a K2 i K3 to budynek T
    let buildingNumber;
    if (num === "K1") {
      buildingNumber = "L";
    } else if (num === "K2" || num === "K3") {
      buildingNumber = "T";
    } else if (num === "K4" || num === "K5") {
      buildingNumber = "C";
    }
    return buildingNumber;
  };

  filterApartments = el => {
    let filteredArray = this.state.filteredApartments.filter(item => {
      if (item.type === el) {
        if (item.isChecked) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
      }
      return item;
    });

    this.setState(
      {filteredApartments: filteredArray},
      this.colorFiltredApartments(this.state.filteredApartments)
    );
  };

  changeBuilding = type => {
    this.props.redirectTo !== undefined &&
      this.props.redirectTo &&
      this.props.redirectTo(`/apartamenty/${type}/${this.state.storey}`);
  };

  changeFloor = type => {
    this.props.redirectTo !== undefined &&
      this.props.redirectTo &&
      this.props.redirectTo(
        `/apartamenty/${this.state.buildingNumber}/${type}`
      );
  };

  getFreeFlatsNumber = (buildingNum, storeyNum) => {
    storeyNum = storeyNum.replace("P", "");

    if (buildingNum == "K1") {
      let newArray = this.props.flats.filter(
        element =>
          element.buildingNumber == buildingNum &&
          element.storey == storeyNum &&
          element.flatStatus == 1
      );
      return newArray.length;
    } else if (buildingNum == "K2" || buildingNum == "K3") {
      let newArray = this.props.flats.filter(
        element =>
          (element.buildingNumber == "K2" || element.buildingNumber == "K3") &&
          element.storey == storeyNum &&
          element.flatStatus == 1
      );
      return newArray.length;
    }
  };

  render() {
    let status = [
      {
        name: "Oglądane",
        class: "current",
      },
      {
        name: "Wolne",
        class: "free",
      },
      {
        name: "Rezerwacja",
        class: "reserved",
      },
      {
        name: "Sprzedane",
        class: "sold",
      },
    ];

    let buildingsButtons = [
      // {
      //   name: "C",
      //   number: "",
      // },
      {
        name: "T",
        number: "K2",
        floors: [
          {name: "6 piętro", number: "P6"},
          {name: "5 piętro", number: "P5"},
          {name: "4 piętro", number: "P4"},
          {name: "3 piętro", number: "P3"},
          {name: "2 piętro", number: "P2"},
          {name: "1 piętro", number: "P1"},
          {name: "parter", number: "P0"},
        ],
      },
      {
        name: "L",
        number: "K1",
        floors: [
          {name: "4 piętro", number: "P4"},
          {name: "3 piętro", number: "P3"},
          {name: "2 piętro", number: "P2"},
          {name: "1 piętro", number: "P1"},
          {name: "parter", number: "P0"},
        ],
      },
    ];

    return (
      <section className="lp-floor-view" id="floor" ref={this.props.reference}>
        <div className="floor-view__container container">
          <h2 className="floor-view__title title-basic">
            Budynek {this.convertBuildingNumber(this.state.buildingNumber)},
            Klatka {this.state.buildingNumber}, Piętro{" "}
            {this.state.storey.replace("P", " ")}
          </h2>
          <div className="floor-view__main row">
            <div className="floor-view__left col-5 col-m-12">
              <div className="floor-view__box">
                <p
                  className="box-select"
                  onClick={() => this.showBuildingButtons()}
                >
                  <span className="box-select__text">
                    {this.state.buildingNumber
                      ? `Budynek: ${this.convertBuildingNumber(
                          this.state.buildingNumber
                        )}`
                      : "Wybierz budynek"}
                  </span>

                  {this.state.buildingsButtonsActive ? (
                    <span className="af-icon-angle-up-solid box-select__icon"></span>
                  ) : (
                    <span className="af-icon-angle-down-solid box-select__icon"></span>
                  )}
                </p>
                <div
                  className={
                    "box" + (this.state.buildingsButtonsActive ? " active" : "")
                  }
                >
                  <span className="text">Budynek</span>

                  <div className="box-choose">
                    {buildingsButtons && buildingsButtons.length > 0
                      ? buildingsButtons.map(bb => (
                          <button
                            className={
                              "choose-building__button" +
                              (this.convertBuildingNumber(
                                this.state.buildingNumber
                              ) === bb.name
                                ? " active"
                                : "")
                            }
                            onClick={() => this.changeBuilding(bb.number)}
                          >
                            {" "}
                            {bb.name}
                          </button>
                        ))
                      : null}
                  </div>
                </div>

                <p
                  className="box-select"
                  onClick={() => this.showFloorsButtons()}
                >
                  <span className="box-select__text">
                    {this.state.storey
                      ? `Piętro: ${this.state.storey.replace("P", " ")}`
                      : "Wybierz pietro"}
                  </span>

                  {this.state.floorsButtonsActive ? (
                    <span className="af-icon-angle-up-solid box-select__icon"></span>
                  ) : (
                    <span className="af-icon-angle-down-solid box-select__icon"></span>
                  )}
                </p>

                <div
                  className={
                    "box" + (this.state.floorsButtonsActive ? " active" : "")
                  }
                >
                  <span className="text">Piętro</span>

                  <div className="box-choose">
                    <div className="choose__buttons">
                      {buildingsButtons && buildingsButtons.length > 0
                        ? buildingsButtons.map(bb => {
                            if (
                              this.convertBuildingNumber(
                                this.state.buildingNumber
                              ) === bb.name
                            ) {
                              return bb.floors.map(floor => (
                                <button
                                  className={
                                    "choose-storey__button" +
                                    (this.state.storey === floor.number
                                      ? " active"
                                      : "")
                                  }
                                  onClick={() => this.changeFloor(floor.number)}
                                >
                                  {" "}
                                  {floor.name}
                                  <span>
                                    {this.getFreeFlatsNumber(
                                      bb.number,
                                      floor.number
                                    )}{" "}
                                    wolnych
                                  </span>
                                </button>
                              ));
                            }
                          })
                        : null}
                    </div>
                  </div>
                </div>

                <p
                  className="box-select"
                  onClick={() => this.showTypesButtons()}
                >
                  <span className="box-select__text">Typ mieszkania</span>

                  {this.state.apartmentTypesActive ? (
                    <span className="af-icon-angle-up-solid box-select__icon"></span>
                  ) : (
                    <span className="af-icon-angle-down-solid box-select__icon"></span>
                  )}
                </p>

                <div
                  className={
                    "box" + (this.state.apartmentTypesActive ? " active" : "")
                  }
                >
                  <span className="text">Pokaż</span>

                  <div className="box-choose">
                    <div className="choose__buttons">
                      {this.apartmensType.length > 0
                        ? this.apartmensType.map((item, index) => (
                            <button
                              key={`button+${index}`}
                              className={
                                "choose-flats__button" +
                                (this.state.filteredApartments[index].isChecked
                                  ? ` active ${this.state.filteredApartments[index].class}`
                                  : "")
                              }
                              onClick={() => this.filterApartments(item.type)}
                            >
                              <span className="choose__text">{item.name}</span>
                            </button>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="floor-view__right col-7 col-m-12">
              <div
                key={"key2" + this.uuid}
                ref={this.svgParent}
                className={
                  "floor-mapper" + (this.state.loading ? " active" : "")
                }
                id={this.uuid}
              />

              {this.state.loading ? null : (
                <div className="floor-loader">
                  {" "}
                  <Loader />
                </div>
              )}

              <div className="floor-view__info">
                {this.state.hoveredFlat ? (
                  <FloorViewLabel flatNumber={this.state.hoveredFlat} />
                ) : null}

                <div className="floor-view__status">
                  {status.length > 0
                    ? status.map((element, index) => (
                        <div className="status" key={`status${index}`}>
                          <span
                            className={`status-item ${element.class}`}
                          ></span>
                          <span className="status-text">{element.name}</span>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(FloorView);
