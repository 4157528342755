import React from "react";
import "./LocalizationArea.scss";
import localization from '../../assets/images/dluga15-exterior-ujecie6-01.webp';
import localizationA from '../../assets/images/dluga15-exterior-ujecie6-01.jpg';

export default class LocalizationArea extends React.Component {
  render() {
    return (
      <section className="lp-localization-area">
        <div className="localization-area__container container row">
        <div className="localization-area__left col-6 col-m-12">

          <picture className="localization-area__image">
                  <source srcset={localization} type="image/webp" />
                  <source srcset={localizationA} type="image/jpeg" />
                  <img src={localizationA} className="localization-area__image" />
                </picture>
        </div>

          <div className="localization-area__right col-6 col-m-12">
            <h2 className="localization-area__title title-basic">
              Długa 15 i przestrzeń
            </h2>
            <p className="localization-area__text text-basic">
            Za dnia tętniąca życiem, nocą dająca schronienie. Otwarte patia wewnątrz naszej inwestycji , zwrócone do Odry - to idealne miejsce do odpoczynku po intensywnym dniu.
            </p>

          </div>
        </div>
      </section>
    );
  }
}
